import Cadastro from "views/Cadastro.jsx";
import Prontuario from "views/Prontuario.jsx";
import Dashboard from "views/Dashboard.jsx";
import Home from "views/Home.jsx";
import Usuarios from "views/Usuarios.jsx";
import AlmoxBeneficios from "views/AlmoxBeneficios.jsx";
import AlmoxProdutos from "views/AlmoxProdutos.jsx";
import AlmoxMovimentos from "views/AlmoxMovimentos.jsx";
import AlmoxTransferir from "views/AlmoxTransferir.jsx";
import AlmoxEstoque from "views/AlmoxEstoque.jsx";
import Oficinas from "views/Oficinas.jsx";
import Perfil from "views/Perfil.jsx";
import Cargos from "views/Cargos";
import CategoriaAlmoxarifado from "views/Almoxarifado/CategoriaAlmoxarifado";
import ProdutoAlmoxarifado from "views/Almoxarifado/ProdutoAlmoxarifado";
import Relatorio from "views/Relatorio/Relatorio";
import Integracoes from "views/Integracoes";
import Log from "views/Log";
import Creas from "views/Creas";
import EncaminhamentoRecebido from "views/ModAtendimento/EncaminhamentoRecebido";
import Scfv from "views/Scfv";
import Comunicacao from "views/Comunicacao";
import Conselhos from "views/Conselhos";
import ListaPerfil from "views/Perfis/ListaPerfil";
import Despesas from "views/Financeiro/Despesas";
import Entradas from "views/Financeiro/Entradas";
import Suporte from "views/Suporte";
import Equipes from "views/Equipes";
import Area from "views/Area";
import Servico from "views/Servico";
import ConfigFormularioExtra from "views/Configuracoes/ConfigFormularioExtra";

// import UserProfile from "views/UserProfile.jsx";
// import TableList from "views/TableList.jsx";
// import Typography from "views/Typography.jsx";
// import Icons from "views/Icons.jsx";
// import Maps from "views/Maps.jsx";
// import Notifications from "views/Notifications.jsx";
// import Upgrade from "views/Upgrade.jsx";


const dashboardRoutes = ()=>{

  return [
    {
      path: "/home", name: "Inicial", icon: "pe-7s-home",
      component: Home,
      layout: "/admin", allow: "",
      type: []
    },
    {
      path: "/cadastro", name: "Novo Atendimento", icon: "pe-7s-note",
      component: Cadastro,
      layout: "/admin", allow: "1",
      type: []
    },
    {
      path: "/entrada", name: "Encaminhamentos Recebidos", icon: "pe-7s-mail",
      component: EncaminhamentoRecebido,
      layout: "/admin", allow: "enca",
      type: []
    },
    {
      path: "/prontuario", name: "Buscar Atendimento", icon: "pe-7s-note2",
      component: Prontuario,
      layout: "/admin", allow: "2",
      type: []
    },
    {
      path: "/oficinas", name: "Projetos e Oficinas", icon: "pe-7s-users",
      component: Oficinas,
      layout: "/admin", allow: "ofic",
      type: []
    },
    {
      path: "/scfv", name: "Programas e Convicências", icon: "pe-7s-users",
      component: Scfv,
      layout: "/admin", allow: "conv",
      type: []
    },
    {
      path: "/user", name: "Usuários", icon: "pe-7s-id",
      component: Usuarios,
      layout: "/admin", allow: "usua",
      type: []
    },{
      path: "/equipe", name: "Equipes", icon: "pe-7s-users",
      component: Equipes,
      layout: "/admin", allow: "equi",
      type: []
    },{
      path: "/area", name: "Áreas", icon: "pe-7s-map-marker",
      component: Area,
      layout: "/admin", allow: "area",
      type: []
    },
    {
      path: "/beneficios", name: "Benefícios", icon: "pe-7s-box2",
      component: AlmoxBeneficios,
      layout: "/admin", allow: "bene",
      type: []
    },
    {
      path: "/movimentos", name: "Movimentos", icon: "pe-7s-next",
      component: AlmoxMovimentos,
      layout: "/admin", allow: "movi",
      type: []
    },
    {
      path: "/transferir", name: "Transferir", icon: "pe-7s-loop",
      component: AlmoxTransferir,
      layout: "/admin", allow: "tran",
      type: []
    },
    {
      path: "/estoque", name: "Estoque Geral", icon: "pe-7s-menu",
      component: AlmoxEstoque,
      layout: "/admin", allow: "6",
      type: []
    },
    {
      path: "/almoxarifado/categoria", name: "Categorias", icon: "pe-7s-folder",
      component: CategoriaAlmoxarifado,
      layout: "/admin", allow: "cate",
      type: []
    },
    {
      path: "/almoxarifado/produto", name: "Produto", icon: "pe-7s-file",
      component: ProdutoAlmoxarifado,
      layout: "/admin", allow: "prod",
      type: []
    },
    {
      path: "/relatorio", name: "Relatório", icon: "pe-7s-box1",
      component: Relatorio,
      layout: "/admin", allow: "7",
      type: []
    },
    {
      path: "/comunicacao", name: "Comunicação", icon: "pe-7s-phone",
      component: Comunicacao,
      layout: "/admin", allow: "comu",
      type: []
    },
    {
      path: "/integracao", name: "Integrações", icon: "pe-7s-share",
      component: Integracoes,
      layout: "/admin", allow: "8",
      type: []
    },
    {
      path:'/conselhos', name: "Conselhos", icon: "pe-7s-map-2 ",
      component: Conselhos,
      layout: "/admin", allow: "cons",
      type: []
    },{
      path: "/despesas", name: "Despesas", icon: "pe-7s-cash",
      component: Despesas,
      layout: "/admin", allow: "desp",
      type: []
    },{
      path: "/entradas", name: "Recursos", icon: "pe-7s-piggy",
      component: Entradas,
      layout: "/admin", allow: "entr",
      type: []
    },{
      path: "/perfil", name: "Perfil", icon: "pe-7s-user",
      component: Perfil,
      layout: "/admin", allow: "",
      type: []
    },{
      path: "/servicos", name: "Serviços", icon: "pe-7s-like2",
      component: Servico,
      layout: "/admin", allow: "serv",
      type: []
    },{
      path: "/config", name: "Configurações", icon: "pe-7s-config",
      component: ConfigFormularioExtra,
      layout: "/admin", allow: "conf",
      type: []
    },{
      path: "/locais", name: "Locais", icon: "pe-7s-map-2 ",
      component: Creas,
      layout: "/admin", allow: "loca",
      type: []
    },
    {
      path: "/cargos", name: "Cargos", icon: "pe-7s-notebook ",
      component: ListaPerfil,
      layout: "/admin", allow: "carg",
      type: ['GER']
    },{
      path: "/Log", name: "Log de acessos", icon: "pe-7s-monitor ",
      component: Log,
      layout: "/admin", allow: "9",
      type: ['GER']
    },{
      path: "/suporte", name: "Suporte", icon: "pe-7s-help1",
      component: Suporte,
      layout: "/admin", allow: "",
      type: []
    },
  ]
};

export default dashboardRoutes;
