import { Backdrop, CircularProgress } from '@material-ui/core';
import CrudView from 'components/Crud/CrudView';
import { actionIsAllowed } from 'helpers/Permissions';
import { LaConFetch } from 'links/LaConFetch';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';

export default function Usuarios({...props}){
    const [allowed,setAllowed] = useState({
        add: false, edit:false, delete:false
    })
    const [sending,setSending] = useState(false)

    useEffect(()=>{
        setAllowed({
            edit: actionIsAllowed('prod','update'),
            delete: actionIsAllowed('prod','delete'),
            add:actionIsAllowed('prod','create'),
        })
    },[])

    const onDisable=(id,mode)=>{
        setSending(true)
        let form ={}
        LaConFetch(`/api/user-geral/disable/${id}`,(r)=>{
            setSending(false)
            if(r.success){
                swal(`${mode ? 'Habilitado':'Desabilitado'} com sucesso!`,"", "success");
            }else{
                swal("Oops",r.message, "error");
            }
        },()=>{},form)
    }

    const onAskDisable=(id,mode=false)=>{
        swal(`Tem certeza que deseja ${mode ? 'habilitar':'desabilitar'} o usuário?`, {
            buttons: {
            remover: mode ? 'Habilitar':'Desabilitar',
            cancel: "Sair"
            },
        }).then((value) => {
            switch (value) {
            case "remover":
                onDisable(id,mode)
                break;
            }
        })
    }

    return <div>
        <Backdrop style={{zIndex:99}} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <CrudView
            url={`/api/user-geral`} title={'Usuário'}
            columns={[
                {field:'name',title:'Nome'},
                {field:'email',title:'E-mail'},
                {field:null,title:'Cargo',render:(row)=>{
                    return row?.perfil?.nome ?? 'N/D'
                }},
            ]}
            canEdit={allowed.edit} canDelete={allowed.delete} canAdd={allowed.add} remoteLoad={true}
            extraAction={rowData =>({
                icon:rowData.disabled ? 'visibility':'visibility_off',
                tooltip:rowData.disabled ? 'Habilitar':'Desabilitar',
                onClick:(e,data)=>{
                    onAskDisable(data.id,rowData.disabled)
                }
            })}
            fields={[
                {
                    field:'name',type:"text",grid:{xs:6},label:'Nome *'
                },{
                    field:'email',type:"text",grid:{xs:6},label:'E-mail *'
                },{
                    field:'type',type:'select',link:'/api/user-tipos',dataKey:'id', nameKey:'nome',label:'Dashboard *',grid:{xs:6}
                },{
                    field:'perfil_id',type:'select',link:'/api/perfis/small',dataKey:'id', nameKey:'nome',label:'Nível de acesso *',grid:{xs:6}
                },{
                    field:'locais_id',type:'multi-select',link:'/api/creas',dataKey:'id', nameKey:'nome',label:'Local *',grid:{xs:12}
                },{
                    field:'start',type:"date",grid:{xs:6},label:'Início do Acesso',
                },{
                    field:'end',type:"date",grid:{xs:6},label:'Fim do Acesso',
                },{
                    field:'cpf',type:"text",grid:{xs:6},label:'CPF'
                },{
                    field:'celular',type:"text",grid:{xs:6},label:'Telefone'
                },{
                    field:'conselho',type:"text",grid:{xs:6},label:'Conselho',
                },{
                    field:'profissao',type:"text",grid:{xs:6},label:'Profissão',
                },{
                    field:'cargo',type:"text",grid:{xs:6},label:'Cargo',
                },{
                    field:'funcao',type:"text",grid:{xs:6},label:'Função',
                },{
                    field:'foto',type:'file',grid:{xs:6},label:"Foto", input: {inputProps:{accept: 'image/*'}}
                },{
                    field:'tentativas_acesso',type:"text",grid:{xs:6},label:'Tentativas de conexão',
                },{
                    field:'dias_senha',type:"text",grid:{xs:6},label:'Expiração de senha',
                },{
                    field:'tempo_sessao',type:"text",grid:{xs:6},label:'Expirar sessão',
                },
            ]}
        />
    </div>
}