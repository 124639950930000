import CrudView from 'components/Crud/CrudView';
import React from 'react';

export default function ConfigFormularioExtra({...props}){
    return <div>
        <CrudView
            url='/api/extra/perguntas' title='Perguntas Formulário Extra'
            columns={[
                {title:'Ordem',field:'ordem'},
                {title:'Pergunta',field:'pergunta'},
            ]}
            fields={[
                {
                    field:'pergunta', label: 'Pergunta',type:'text',grid:{xs:12}
                },{
                    field:'descricao', label: 'Descrição',type:'text',grid:{xs:12}, input: {multiline:true, rows:4}
                },{
                    field:'ordem', label: 'Ordem',type:'text',grid:{xs:6}, input: {type:'number'}
                },{
                    field:'helper', label: 'Ajuda',type:'text',grid:{xs:6}
                },{
                    field:'enabled', label: 'Habilitado',type:'checkbox',grid:{xs:6}
                },{
                    field: 'modo_id', label: 'Modo',type:'select',grid:{xs:6},link:'/api/extra/modos',nameKey:'nome',dataKey:'id'
                },{
                    field: 'options', label: 'Opções',type:'array',grid:{xs:12}
                },{
                    field: 'obs', label: 'Observação',type:'text',grid:{xs:12}, input: {multiline:true, rows:4}
                }
            ]}
            topBar={true} dynamic
            canEdit={true} canDelete={true} canAdd={true} canSee={true}
        />
    </div>
}