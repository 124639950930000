import { MenuItem, TextField } from '@material-ui/core'
import { LaConFetch } from 'links/LaConFetch'
import React, { useState, useEffect } from 'react'

export default function SelectFromAnother({link=false,value,onChange,nameKey='nome',dataKey='id',readOnly=false,label,required=false,options=false,...props}){
    const [myOptions,setMyOptions] = useState([])

    useEffect(()=>{
        if(link){
            LaConFetch(`${link}`,(r)=>{
                if(r.success){
                    setMyOptions(r.items)
                }
            },()=>{})
        }else if(options){
            setMyOptions(myOptions)
        }
    },[link,options])
    return <div>
        <TextField
            variant="outlined" fullWidth margin='normal'
            value={value} required={required}
            label={label} disabled={readOnly}
            onChange={(e) => onChange(e.target.value)} select
            {...props}
        >
            <MenuItem value="">Nenhuma opção</MenuItem>
            {myOptions.map(item=><MenuItem value={item[dataKey]} key={item[dataKey]}>{item[nameKey]}</MenuItem>)}
        </TextField>
    </div>
}