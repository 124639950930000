import React, { useEffect, useState } from 'react';
import { _materialTableIcons, _materialTableLocalization } from 'constants/MaterialConstant';
import MaterialTable from 'material-table';
import { LaConFetch } from 'links/LaConFetch';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import { actionIsAllowed } from 'helpers/Permissions';
import EditarEntrada from './EditarEntrada';

export default function Entradas({...props}){
    const [entradas,setEntradas] = useState([])
    const [edit,setEdit] = useState(0)
    const [novo,setNovo] = useState(false)
    const [success,setSuccess] = useState(false)
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState({has:false,message:''})
    const [allowed,setAllowed] = useState({
        add: false, edit:false, delete:false
    })
    const history = useHistory()

    useEffect(()=>{
        setAllowed({
            edit: actionIsAllowed('entr','update'),
            delete: actionIsAllowed('entr','delete'),
            add:actionIsAllowed('entr','create'),
        })
    },[])

    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        setLoading(true)
        LaConFetch(`/api/financeiro/entrada/index`,(r)=>{
            if(r.success){
                setEntradas(r.items)
                setSuccess(true)
            }else{
                setError({has:true,message:r.message})
            }
            setLoading(false)
        },()=>{
            setError({has:true,message:'Erro ao carregar os dados'})
            setLoading(false)
        })
    }

    const onClose=(reload=false)=>{
        setEdit(0)
        setNovo(false)
        if(reload) loadData()
    }

    const handleOpen=()=>{
        setNovo(true)
    }

    const onDelete=(id)=>{
        setLoading(true)
        let form ={}
        LaConFetch(`/api/financeiro/entrada/delete/${id}`,(r)=>{
            setLoading(false)
            if(r.success){
                swal("Entrada removida com sucesso!","", "success");
            }else{
                swal("Oops","Houve um erro ao remover a entrada", "error");
            }
            loadData()
        },()=>{},form)
    }

    const onAskDelete=(id,title)=>{
        swal(`Tem certeza que deseja remover a entrada ${title}?`, {
            buttons: {
              remover: "Remover",
              cancel: "Sair"
            },
          })
          .then((value) => {
            switch (value) {
              case "remover":
                onDelete(id)
                break;
            }
          })
    }

    let actions=[]

    if(allowed.edit){
        actions.push({
            icon:'edit',
            tooltip:'Editar',
            onClick:(e,data)=>{
                setEdit(data.id)
                setNovo(false)
            }
        })
    }
    if(allowed.delete){
        actions.push({
            icon:'delete',
            tooltip:'Remover',
            onClick:(e,data)=>{
                onAskDelete(data.id,data.nome)
            }
        })
    }

    return <div className="justify-content-center ml-5">
        <div className="bg-primary" style={{backgroundColor:'rgb(37, 85, 39)',padding:'10px'}}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <h4 style={{margin: '3px 0px'}}>Recursos Financeiros</h4>
                {novo || edit > 0 ? <Button onClick={()=>onClose(false)} variant="contained" color="primary">
                    Voltar
                </Button>: allowed.add ? <Button onClick={handleOpen} variant="contained" color="primary">
                    Nova Recurso
                </Button>:null}
            </div>
        </div>
        {novo || edit > 0 ? <EditarEntrada
            onClose={onClose} novo={novo} edit={edit}
        />:<MaterialTable
            icons={_materialTableIcons} localization={_materialTableLocalization}
            title='Recursos' data={entradas} isLoading={loading}
            columns={[
                { title: 'Nome', field: 'nome' },
                { title: 'Valor', field: 'valor', render:(data)=>{
                    return `R$ ${data?.valor}`
                } },
                { title: 'Início', field: 'start_date',render:(data)=>{
                    if(data?.start_date){
                        return (new Date(data?.start_date)).toLocaleDateString()
                    }
                    return 'N/D'
                } },
                { title: 'Fim', field: 'end_date',render:(data)=>{
                    if(data?.end_date){
                        return (new Date(data?.end_date)).toLocaleDateString()
                    }
                    return 'N/D'
                } },
                { title: 'Tipo', field: 'tipo.nome' },
            ]}
            actions={actions}
            options={{
                actionsColumnIndex: -1, pageSize:10,
            }}
        />}
    </div>
}