import { LaConFetch } from 'links/LaConFetch';
import React, { useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function AgeGraph({...props}){
    const [data,setData] = useState([])

    useEffect(()=>{
        LaConFetch('/api/graph/idade',(r)=>{
            if(r.success){
                setData(r.data)
            }
        },()=>{

        })
    },[])

    return <div style={{width:'100%',height:'100%'}}>
        <b style={{fontSize:16}}>Beneficiados por Faixa Etária</b>
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500} height={300}
                data={data}
                margin={{
                    top: 5, right: 30,
                    left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" label="Beneficiados" fill="#00fa3a" />
            </BarChart>
        </ResponsiveContainer>
    </div>
}