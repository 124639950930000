import { Backdrop, Button, CircularProgress, Paper, TextField } from '@material-ui/core';
import { LaConFetch } from 'links/LaConFetch';
import React, { useState } from 'react';
import swal from 'sweetalert';

export default function Suporte({...props}){
    const [assunto,setAssunto] = useState('')
    const [mensagem,setMensagem] = useState('')
    const [email,setEmail] = useState('')
    const [loading,setLoading] = useState(false)
    
    const handleSubmit = () => {
        setLoading(true)
        let form ={
            assunto,
            mensagem,
            email
        }
        LaConFetch(`/api/suporte/message`,(r)=>{
            if(r.success){
                swal("Sucesso","Mensagem enviada com sucesso","success")
            }else{
                swal("Erro",r.message,"error")
            }
            setLoading(false)
        },()=>{
            swal("Erro","Não foi possível enviar a mensagem","error")
            setLoading(false)
        },form)
    }

    return <div>
        <Backdrop style={{zIndex:99}} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <div className="bg-primary" style={{backgroundColor:'rgb(37, 85, 39)',padding:'10px'}}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <h4 style={{margin: '3px 0px'}}>Suporte ao Usuário</h4>
            </div>
        </div>
        <Paper style={{padding:5}}>
            <TextField
                label="Assunto" fullWidth margin="normal" variant="outlined"
                value={assunto} onChange={(e)=>setAssunto(e.target.value)}
            />
            <TextField
                label="Mensagem" fullWidth margin="normal"
                value={mensagem} onChange={(e)=>setMensagem(e.target.value)}
                multiline rows={6} variant="outlined"
            />
            <div style={{display:"flex",justifyContent:"end"}}>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    Enviar Mensagem
                </Button>
            </div>
        </Paper>
    </div>
}