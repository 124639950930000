import React,{useEffect,useState} from 'react';
import MaterialTable from 'material-table'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EventIcon from '@material-ui/icons/Event';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ThumbUp from '@material-ui/icons/ThumbUp';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import NoteIcon from '@material-ui/icons/Note';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HistoryIcon from '@material-ui/icons/History';
import PrintIcon from '@material-ui/icons/Print';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import MapIcon from '@material-ui/icons/Map';

import SolicitaBeneficio from './ModAtendimento/SolicitaBeneficio';
import Encaminhamento from './ModAtendimento/Encaminhamento';

import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Atendimento from './TabProntuario/Atendimento';
import Responsavel from './TabProntuario/Responsavel';
import Dependente from './TabProntuario/Dependente';
import CondHabitacionais from './TabProntuario/CondHabitacionais';
import CondEducacionais from './TabProntuario/CondEducacionais';
import CondTrabalho from './TabProntuario/CondTrabalho';
import Deficiencia from './TabProntuario/Deficiencia';
import Cuidados from './TabProntuario/Cuidados';
import Rendimentos from './TabProntuario/Rendimentos';
import Beneficios from './TabProntuario/Beneficios';
import Convivencia from './TabProntuario/Convivencia';
import Participacao from './TabProntuario/Participacao';
import Violencia from './TabProntuario/Violencia';
import MedidaSocioEduc from './TabProntuario/MedidaSocioEduc';
import Acolhimento from './TabProntuario/Acolhimento';
import EstudoSocial from './TabProntuario/EstudoSocial';
import Gestacao from './TabProntuario/Gestacao';
import DescumprimentoEducacao from './TabProntuario/DescumprimentoEducacao';
import DescumprimentoSaude from './TabProntuario/DescumprimentoSaude';
import CreasAcompanhamento from './TabProntuario/CreasAcompanhamento';
import CreasViolencia from './TabProntuario/CreasViolencia';
import Familia from './TabProntuario/Familia';
import { CircularProgress, Backdrop, Modal, Paper, TextField } from '@material-ui/core';
import {link} from '../links/Links';
import { LaConFetch } from 'links/LaConFetch';
import Agenda from './Agenda/Agenda';
import Historico from './Historico';
import Carteira from './Carteira';
import { LaConFileUpload } from 'links/LaConFetch';
import swal from 'sweetalert';
import { transpileModule } from 'typescript';
import { _MaterialTableLocalization } from 'links/TableConstant';
import ParticipacaoScfv from './TabProntuario/ParticipacaoScfv';
import ModaAnexo from 'components/Crud/ModaAnexo';
import CardResponsavel from './TabProntuario/CardResponsavel';
import FormularioExtra from './TabProntuario/FormularioExtra';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index, current) {
  return {
    nomeAtivo: index === current,
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({

  root: {
    flexGrow: 1,
    width: '100%',
  },

  Tabs: {
    backgroundColor: 'green',
    color: 'white',
},
}));

/* TAG CRIADA */
function TabFixed({nome="",nomeAtivo=false, ...props}){
  return <Tooltip title={<div style={{ fontSize: 12,margin:5 }}>{nome}</div>}>
          <Tab label={nomeAtivo?nome:''} style={{ minWidth: nomeAtivo?150:0 }} {...props}/>
         </Tooltip>  
};

export default function Prontuario() {
  const [sending, setSending] = useState(false);

  /* Oculta as Tabs */
  const [off, setOff] = useState(true);
  const [numtab, setNumTab] = useState(1);

  /* ID da família */
  const [familia_id, setFamilia_id] = useState();
  const [responsavel, setResponsavel] = useState();
  const [value, setValue] = useState(0);

  const classes = useStyles();
 
  const changeMain = (num=null) => {
    if (num && numtab!=num) {
      setNumTab(num)
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const iniciaProntuario = (familiaId) => {
    setFamilia_id(familiaId)
    loadResponsavel(familiaId)
    setNumTab(1)
    setOff(false)
  }

  const loadResponsavel = (familiaId) => {
    setSending(true);
    LaConFetch(`/api/resultResponsavel/${familiaId}`,
      result => {
        if(result.dados !== null){
          setResponsavel(result.dados[0])
          setSending(false);
        }
      },result => setSending(false))
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const [carteira,setCarteira] = useState(false)

  const selectRow=(data)=>{
    iniciaProntuario(data.familia_id);
    setSelectedRow(data.tableData.id);
    setValue(1)
  }

  let actions = [
      {
        icon: 'edit',
        tooltip: 'Editar',
        onClick: (e,data) => {selectRow(data)}
      },
  ]
  let _type = localStorage.getItem('type');
  if(_type == 'REC'){
    actions = []
  }

  return <div className={classes.root}>
      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>
      {(!off) ? <Card style={{margin:'7px',padding:'10px 12px'}}>
        <Grid container>
          <Grid item lg={4}>
            <CardResponsavel familiaId={familia_id}/>
          </Grid>
          <Grid item lg={8}>
            <Grid container>
              <Grid item xs={12} lg={3}>
                <Button variant={(numtab==1)?"contained":"outlined"} onClick={()=>changeMain(1)} color="primary" style={{width:'100%', padding:'20px 10px'}} startIcon={<ReceiptIcon />}>
                Prontuário
              </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button variant={(numtab==2)?"contained":"outlined"} onClick={()=>changeMain(2)} color="primary" style={{width:'100%', padding:'20px 10px'}} startIcon={<FavoriteIcon />}>
                Benefícios
              </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button variant={(numtab==3)?"contained":"outlined"} onClick={()=>changeMain(3)} color="primary" style={{width:'100%', padding:'20px 10px'}} startIcon={<ArrowForwardIcon />}>
                Encaminhamentos
              </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button variant={(numtab==4)?"contained":"outlined"} onClick={()=>changeMain(4)} color="primary" style={{width:'100%', padding:'20px 10px'}} startIcon={<EventIcon />}>
                Agendamentos
              </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button variant={(numtab==5)?"contained":"outlined"} onClick={()=>changeMain(5)} color="primary" style={{width:'100%', padding:'20px 10px'}} startIcon={<HistoryIcon />}>
                Histórico
              </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button variant={(numtab==6)?"contained":"outlined"} onClick={()=>changeMain(6)} color="primary" style={{width:'100%', padding:'20px 10px'}} startIcon={<PrintIcon />}>
                Imprimir
              </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button variant={(numtab==7)?"contained":"outlined"} onClick={()=>setCarteira(true)} color="primary" style={{width:'100%', padding:'20px 10px'}} startIcon={<RecentActorsIcon />}>
                Carteirinha
              </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <ModaAnexo
                  bigButton url="familia-anexo" endLink={`?familia_id=${familia_id}`}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card> : null}
      {(numtab==1) ? (<Card style={{margin:'7px'}}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons="on"
          aria-label="scrollable prevent tabs example"
          className={classes.Tabs}
        >
          <TabFixed nome="Busca" icon={<SearchIcon />} aria-label="SearchIcon" {...a11yProps(0,value)} />
          {off ? null:<TabFixed nome="Atendimento" icon={<ContactPhoneIcon />} aria-label="contactPhoneIcon" {...a11yProps(1,value)} />}
          {off ? null:<TabFixed nome="Família" icon={<SupervisedUserCircleIcon />} aria-label="supervisedUserCircle" {...a11yProps(2,value)} />}
          {off ? null:<TabFixed nome="Responsável" icon={<PersonIcon />} aria-label="person" {...a11yProps(3,value)} />}
          {off ? null:<TabFixed nome="Dependentes" icon={<GroupIcon />} aria-label="group" {...a11yProps(4,value)} />}
          {off ? null:<TabFixed nome="Condições Habitacionais" icon={<HomeIcon />} aria-label="home" {...a11yProps(5,value)} />}
          {off ? null:<TabFixed nome="Condições Educacionais" icon={<SchoolIcon />} aria-label="school" {...a11yProps(6,value)} />}
          {off ? null:<TabFixed nome="Condições Trabalho" icon={<WorkIcon />} aria-label="work" {...a11yProps(7,value)} />}
          {off ? null:<TabFixed nome="Condições Saúde" icon={<LocalHospitalIcon />} aria-label="localHospital" {...a11yProps(8,value)} />}
          {off ? null:<TabFixed nome="Rendimentos Família" icon={<MonetizationOnIcon />} aria-label="monetizationOn" {...a11yProps(9,value)} />}
          {off ? null:<TabFixed nome="Benefícios Eventuais" icon={<EventIcon />} aria-label="event" {...a11yProps(10,value)} />}
          {off ? null:<TabFixed nome="Convivência" icon={<GroupWorkIcon />} aria-label="groupWork" {...a11yProps(11,value)} />}
          {off ? null:<TabFixed nome="Participação" icon={<ThumbUp />} aria-label="down" {...a11yProps(12,value)} />}
          {off ? null:<TabFixed nome="Violência" icon={<MoodBadIcon />} aria-label="moodBad" {...a11yProps(13,value)} />}
          {off ? null:<TabFixed nome="Medida Socioeducativa" icon={<LocalLibraryIcon />} aria-label="localLibrary" {...a11yProps(14,value)} />}
          {off ? null:<TabFixed nome="Acolhimento" icon={<FavoriteIcon />} aria-label="favorite" {...a11yProps(15,value)} />}
          {off ? null:<TabFixed nome="Estudo Social" icon={<AssessmentIcon />} aria-label="assessment" {...a11yProps(16,value)} />}
          {off ? null:<TabFixed nome="Formulário Extra" icon={<NoteIcon />} aria-label="extra" {...a11yProps(17,value)} />}
        </Tabs>
      </AppBar>
      
      <TabPanel value={value} index={0}>
        <div className="justify-content-center ml-5">
          <MaterialTable 
            columns={[
              { title: 'Cód. Família', field: 'familia_id', headerStyle: {fontWeight: 'bold'} },
              { title: 'Responsável', field: 'nomeResponsavel', headerStyle: {fontWeight: 'bold'} },
              { title: 'Apelido', field:'apelido', headerStyle: {fontWeight: 'bold'}},
              { title: 'CPF', field:'cpf', headerStyle: {fontWeight: 'bold'}},
              { title: 'RG', field:'rg', headerStyle: {fontWeight: 'bold'}},
              { title: 'NIS', field:'nis', headerStyle: {fontWeight: 'bold'}},
              { title: 'Endereço', field:'endereco', headerStyle: {fontWeight: 'bold'}},
              { title: 'Último atendimento', field: 'ultimoAtendimento', headerStyle: {fontWeight: 'bold'} },
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                let url = `/api/resultBusca?`
                url += 'page_size=' + query.pageSize
                url += '&page=' + (query.page + 1)
                if(query.search){
                  url += '&search=' + query.search
                }
                  LaConFetch(url,(r)=>{
                    if(r.success){
                      resolve({
                          data: r.dados,
                          page: r.page-1,
                          totalCount: r.total
                      });
                    }else{
                      reject()
                    }
                  },()=>{
                    reject()
                  })
              })} actions={actions}
            onRowClick={(event, rowData) => {
              selectRow(rowData)
            }}
            components={{
              Container: props => <div {...props} />,
            }}
            options={{
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                })
            }}
          
          localization={_MaterialTableLocalization}
            title="BUSCA"
          />
        </div>
        
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Atendimento familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Familia familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={3}>
        <Responsavel familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={4}>
        <Dependente familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={5}>
        <CondHabitacionais familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={6}>
        <CondEducacionais familia_id={familia_id}/>
        <DescumprimentoEducacao familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={7}>
        <CondTrabalho familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={8}>
        <Deficiencia familia_id={familia_id}/>
        <Cuidados familia_id={familia_id}/>
        <Gestacao familia_id={familia_id}/>
        <DescumprimentoSaude familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={9}>
        <Rendimentos familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={10}>
        <Beneficios familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={11}>
        <Convivencia familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={12}>
        <Participacao familia_id={familia_id}/>
        <ParticipacaoScfv familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={13}>
        <Violencia familia_id={familia_id}></Violencia> 
        <CreasAcompanhamento familia_id={familia_id}/>
        <CreasViolencia familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={14}>
        <MedidaSocioEduc familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={15}>
        <Acolhimento familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={16}>
        <EstudoSocial familia_id={familia_id}/>
      </TabPanel>

      <TabPanel value={value} index={17}>
        <FormularioExtra familiaId={familia_id}/>
      </TabPanel>
      </Card>) : null}
      
      {(numtab==2) ? (
        <Card style={{margin:'7px'}}>
          <SolicitaBeneficio familia_id={familia_id} />
        </Card>
      ) : null}

    {(numtab==3) ? (
        <Card style={{margin:'7px'}}>
          <Encaminhamento familia_id={familia_id} />
        </Card>
      ) : null}
    {(numtab==4) ? (
        <Card style={{margin:'7px'}}>
          <Agenda familia_id={familia_id}/>
        </Card>
      ) : null}
    {(numtab==5)?<Card style={{margin:'7px'}}>
        <Historico familia_id={familia_id}/>
      </Card>:null}
    <Modal open={carteira} onClose={()=>setCarteira(false)}>
      <div className='container' style={{marginTop:50}}>
        <Carteira responsavel={responsavel}/>
      </div>
    </Modal>
  </div>
}
