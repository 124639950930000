import React from 'react';
import { Route } from 'react-router';
import { NavLink } from 'react-router-dom';
import dashboardRoutes from 'routes';

export function SwitchRoutes(){
    let type = localStorage.getItem('type');
    let permissao = localStorage.getItem('permissao');
    const finalRoutes = dashboardRoutes();
    return finalRoutes.map((prop, key) => {
        if (routeIsAllowed(prop,type,permissao)) {
            return (
            <Route
                path={prop.layout + prop.path}
                render={props => (
                <prop.component
                    {...props}
                />
                )}
                key={key}
            />
            );
        } else {
            return null;
        }
    });
}

export function SidebarRoutes({activeRoute,...props}){
    let type = localStorage.getItem('type');
    let permissao = localStorage.getItem('permissao');
    let routes = dashboardRoutes();
    return routes.map((prop, key) => {
        if (routeIsAllowed(prop,type,permissao)){
            return <li
                className={prop.upgrade ? "active active-pro" : activeRoute(prop.layout + prop.path)}
                key={key}
            >
                <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link" activeClassName="active"
                >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                </NavLink>
            </li>
        }
        return null;
      })
}

export function actionIsAllowed(code,action){
    let permissao = localStorage.getItem('permissao');
    let lowerPermissao = permissao.toLowerCase();
    let checker = permissao.substring(lowerPermissao.indexOf(code),lowerPermissao.indexOf(code)+4)
    let actionsIndex = {
        see: 0, create: 1, update: 2, delete: 3,
    }
    if(checker){
        return checker[actionsIndex[action]] === checker[actionsIndex[action]].toUpperCase()
    }
    return false
}

function routeIsAllowed(route, type, permissao=''){
    return route.type.indexOf(type) !== -1 || permissao.toLowerCase().indexOf(route.allow) !== -1
}