import React,{useState, useContext} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CircularProgress, Backdrop, Modal } from '@material-ui/core';
import { useHistory } from 'react-router';
import logo from "assets/img/sads-logo.png";
import swal from 'sweetalert';
import {link, oauth} from '../links/Links';
import ForgotPassword from 'components/Modals/ForgotPassword';
import { SelfContext } from 'provider/SelfContext';
import ReCAPTCHA from 'react-google-recaptcha';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{color:'#fff'}}>
      <span>Licenciado para: Versão Demonstrativa</span><br/>
      <Link color="inherit" href="https://f5webnet.com.br">
        SADS
      </Link>
      {' - Copyright © '+new Date().getFullYear()+' - F5 WebNet'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn({atualizar}) {
  const history = useHistory();

  const classes = useStyles();

  const [sending, setSending] = useState(false);
  const [canGo,setCanGo] = useState(false)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPass,setForgotPass] = useState(false)

  const {token,updateToken} = useContext(SelfContext)

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && canGo) {
      enviar()
    }
  }  

  const updateKey=(result=false)=>{
    setCanGo(result)
  }

  const enviar = () => {
    if(canGo){
      const formDados = {
        username:email,
        password, 
        grant_type:'password',
        scope:'',
        client_id:'2',
        client_secret:oauth

      };

      setSending(true);

      fetch(`${link}/api/enviarLogin`,{
          method:'POST',
          body:JSON.stringify(formDados),
          headers:{
              "Content-Type":'application/json',
              Accept:'application/json'
          }
      })
      .then(res => res.json())
      .then(result => { 
          if(result.success){
              updateToken(result.access_token);
              localStorage.setItem('permissao', result.perfil.access);
              localStorage.setItem('prontuario', result.perfil.prontuario);
              localStorage.setItem('type', result.type);
              localStorage.setItem('name', result.name);
              localStorage.setItem('id', result.id);
              history.push('/admin/home');
          }else{
            swal(result.message,"", "error")
          }
          setSending(false); 
      })
    }
  };

  return (<div style={{backgroundColor:'green'}}>
    <Container component="main" maxWidth="xs">
      <div className="logo-img" style={{paddingTop:50}}>
        <p align="center"><img  src={logo} alt="logo_image" /></p>
        <p align="center" style={{color:'#fff'}}>Software de Apoio ao Desenvolvimento Social</p>
      </div>
      <CssBaseline />
      <div className={classes.paper} style={{backgroundColor:'green'}}>
          
        <Backdrop style={{zIndex:99}} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>
        
        <Box boxShadow={3} style={{backgroundColor:'white', padding:'8%'}}>
          <TextField
            variant="outlined"
            margin="normal"
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            onKeyDown={handleKeyDown}
          />
          <ReCAPTCHA
            sitekey="6LcCDEwgAAAAAMud6TbXToA-93JW-YjJRqWw6UPe"
            onChange={()=>updateKey(true)} onErrored={()=>{updateKey(false);setForgotPass(false)}}
            onExpired={()=>{updateKey(false);setForgotPass(false)}}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{backgroundColor:'green'}}
            className={classes.submit}
            disabled={!canGo}
            onClick={enviar}
          >
            Entrar
          </Button>
          <Button
            type="submit" fullWidth
            variant="text" color="primary"
            className={classes.submit} disabled={!canGo} onClick={()=>setForgotPass(true)}
          >
            Esqueci minha senha
          </Button>

        </Box>

      </div>

      <Box mt={8}>
        <Copyright />
      </Box>
      
    </Container>
    <Modal open={forgotPass} onBackdropClick={()=>setForgotPass(false)}>
      <ForgotPassword onClose={()=>setForgotPass(false)}/>
    </Modal>
    </div>
  );
}