import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {Button, CircularProgress, Backdrop, Modal} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import { LaConFetch } from 'links/LaConFetch';
import {link} from '../../links/Links';

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%'
  },

}));
   
export default function Dependente({familia_id}) {

  const classes = useStyles();
  
  const [id, setId] = useState("");
  const [nm_pessoa, setNm_pessoa] = useState("");
  const [ds_sexo, setDs_sexo] = useState("");
  const [dt_nascimento, setDt_nascimento] = useState("");
  const [cd_parentesco, setCd_parentesco] = useState("");
  const [ds_parentesco, setDs_parentesco] = useState("");
  const [ds_parentesco_outro, setDs_parentesco_outro] = useState("");
  const [st_deficiente, setSt_deficiente] = useState("");
  const [st_documento, setSt_documento] = useState("");
  const [ds_documentacao_civil, setDs_documentacao_civil] = useState("");
  const [documentacaoOff, setDocumentacaoOff] = useState(true);

  useEffect(() => {
    
    if(st_documento === 'Sim'){
      setDocumentacaoOff(false);
    } else{
      setDocumentacaoOff(true);
    }
    
  }, [st_documento])

  /* Desabilitar Outro */
  const [outroGrauOff, setOutroGrauOff] = useState(true);

  /* Set CD_ATENDIMENTO e DS_ATENDIMENTO */
  const setParentesco = (codigo) =>{

    setCd_parentesco(codigo);

    if (codigo === '2'){  
      setDs_parentesco_outro(null);
      setOutroGrauOff(true);
      setDs_parentesco('Cônjuge/companheiro');
    }
    else if (codigo === '3'){
      setDs_parentesco_outro(null);
      setOutroGrauOff(true);
      setDs_parentesco('FIlho(a)');
    }
    else if (codigo === '4'){
      setDs_parentesco_outro(null);
      setOutroGrauOff(true);
      setDs_parentesco('Neto(a)');
    }
    else if (codigo === '5'){
      setDs_parentesco_outro(null);
      setOutroGrauOff(true);
      setDs_parentesco('Bisneto(a)');
    }
    else if (codigo === '6'){
      setDs_parentesco_outro(null);
      setOutroGrauOff(true);
      setDs_parentesco('Pai/Mãe');
    }
    else if (codigo === '7'){
      setDs_parentesco_outro(null);
      setOutroGrauOff(true);
      setDs_parentesco('Sogro(a)');
    }
    else if (codigo === '8'){
      setDs_parentesco_outro(null);
      setOutroGrauOff(true);
      setDs_parentesco('Irmão/irmã');
    }

    else if (codigo === '9'){
      setDs_parentesco_outro(null);
      setOutroGrauOff(true);
      setDs_parentesco('Genro/Nora');
    }

    else if (codigo === '10'){
      setDs_parentesco_outro(null);
      setOutroGrauOff(true);
      setDs_parentesco('Não Parente');
    }

    else if(codigo === '11'){
      setOutroGrauOff(false);
    }

  }

  const setOutroGrau = (texto) =>{

    setDs_parentesco_outro(texto);
    setDs_parentesco(texto);
  }

  const [sending, setSending] = useState(false);

  /* Cadastrar Dependente*/
  const enviar = () => {

    if(nm_pessoa === "")
      swal("Nome é obrigatório!","", "error")

    else if(ds_sexo === "")
      swal("Sexo é obrigatório!","", "error")

    else if(dt_nascimento === "")
      swal("Data de Nascimento é obrigatório!","", "error")

    else if(cd_parentesco === "")
      swal("Grau de parentesco é obrigatório!","", "error")

    else{

      const formDados = {
        familia_id,
        nm_pessoa, 
        ds_sexo,
        dt_nascimento, 
        cd_parentesco,
        ds_parentesco,
        st_deficiente,
        st_documento,
        ds_documentacao_civil,};

      setSending(true);
      LaConFetch(`/api/cadastrarDependente`,
      result => {
        load();
        setSending(false);
        swal("Dependente enviado com sucesso!","", "success")
        setOpen(false)
      },()=>{},formDados)

    }
  };

  /* Editar Dependente*/
  const editar = () => {

    if(nm_pessoa === "")
      swal("Nome é obrigatório!","", "error")

    else if(ds_sexo === "")
      swal("Sexo é obrigatório!","", "error")

    else if(dt_nascimento === "")
      swal("Data de Nascimento é obrigatório!","", "error")

    else if(cd_parentesco === "")
      swal("Grau de parentesco é obrigatório!","", "error")

    else{

      const formDados = {
        nm_pessoa, 
        ds_sexo,
        dt_nascimento, 
        cd_parentesco,
        ds_parentesco,
        st_deficiente,
        st_documento,
        ds_documentacao_civil};

      setSending(true);
      LaConFetch(`/api/editarDependente/${id}`,
      result => {
        load();
        setSending(false);
        swal("Dependente editado com sucesso!","", "success")
        setOpen(false)
      },()=>{},formDados)

    }
  };

  /* Carregar Dependente */
  const [rows, setRows] = useState([]);

  const load = () => {
    setSending(true);
    LaConFetch(`/api/resultDependente/${familia_id}`,
      result => {
        if(result.dados !== null){
          setRows(result.dados)
        }
        setSending(false)
    },result => setSending(false))

  };
  
  useEffect(() => {
    
    load();
    
  }, [])

  /* SetDados */
  const setDados = (dados) => {

    /* Dependente */
    setId(dados.id);
    setNm_pessoa(dados.nm_pessoa);
    setDs_sexo(dados.ds_sexo);
    setDt_nascimento(dados.dt_nascimento);
    setCd_parentesco(dados.cd_parentesco);
    setDs_parentesco(dados.ds_parentesco);
    if(dados.cd_parentesco === '11'){
      setDs_parentesco_outro(dados.ds_parentesco);
      }
    setSt_deficiente(dados.st_deficiente);
    setSt_documento(dados.setSt_documento);
    if (st_documento === 'Sim'){
      setDocumentacaoOff(false);
    }
    setDs_documentacao_civil(dados.ds_documentacao_civil);

  };

  const [open, setOpen] = React.useState(false);

  const [editing, setEditing] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalEdit = (dados) => {
    setDados(dados);
    setEditing(true)
    setOpen(true);
  };

  const modalAdd = () => {
    setNm_pessoa("");
    setDs_sexo("");
    setDt_nascimento("");
    setCd_parentesco("");
    setDs_parentesco("");
    setDs_parentesco_outro("");
    setSt_deficiente("");
    setSt_documento("");
    setDs_documentacao_civil("");
    setDocumentacaoOff(true);
    setOpen(true);
  };

  /* Remover Dependente */
  const remover = (dados) => {

    const id = dados.id;

    setSending(true);
    LaConFetch(`/api/removerDependente/${familia_id}`,
      result => {
        setSending(false)
    },result => setSending(false),{})

  };

  return (
    <div className={classes.root}>
      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <br></br><br></br>
      <Button onClick={modalAdd} style={{ float: 'right' }}  variant="contained" color="primary">
        Adicionar Dependente
      </Button>
      <br></br><br></br>
      <br></br><br></br>
      <div className="justify-content-center ml-5">
        <MaterialTable 
        columns={[
          { title: 'Nome', field: 'nm_pessoa', headerStyle: {fontWeight: 'bold'} },
          { title: 'Sexo', field: 'ds_sexo', headerStyle: {fontWeight: 'bold'} },
          { title: 'Parentesco', field: 'ds_parentesco', headerStyle: {fontWeight: 'bold'} }
        ]}
        data={rows}
        actions={[
            {
              icon: 'edit',
              tooltip: 'Editar',
              onClick: (e,data) => modalEdit(data)
            },
            {
              icon: 'delete',
              tooltip: 'Remover',
              onClick: (e,data) => swal("Tem certeza que deseja remover o registro?", {
                buttons: {
                  remover: "Remover",
                  cancel: "Sair"
                },
              })
              .then((value) => {
                switch (value) {
                  case "remover":
                    remover(data);
                    swal("Removido com sucesso!","", "success");
                    load();
                    break;
                }
              })
            }
        ]}

        options={{
            actionsColumnIndex: -1
        }}
        
        localization={{
            body: {
            emptyDataSourceMessage: 'Nenhum registro para exibir'
            },
            toolbar: {
            searchTooltip: 'Pesquisar',
            searchPlaceholder: 'Pesquisar'
            },
            header: {
            actions: ''
            },
            pagination: {
            labelRowsSelect: 'linhas',
            labelDisplayedRows: '{to} linhas de {count}',
            firstTooltip: 'Primeira página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página'
            }
        }}
        
        title="Dependentes" />
              
      </div>

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>

            <div className={classes.paper}>

              <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Dados Pessoais</InputLabel>
      
              <TextField
                margin="normal"
                style={{ width: '100%' }}  
                id="nm_pessoa"
                label="Nome*"
                variant="outlined"
                value={nm_pessoa} 
                onChange={(e) => setNm_pessoa(e.target.value)}
              />

              <FormControl style={{ marginTop: 15,  marginRight: '2%', width: '20%' }}  component="fieldset">
                <FormLabel component="legend">Sexo*</FormLabel>
                <RadioGroup row aria-label="sexo" name="sexo" value={ds_sexo} onChange={(e) => setDs_sexo(e.target.value)}>
                  <FormControlLabel value="Feminino" control={<Radio />} label="Feminino" />
                  <FormControlLabel value="Masculino" control={<Radio />} label="Masculino" />   
                </RadioGroup>
              </FormControl>

              <TextField
                variant="outlined"
                margin="normal"
                style={{ marginRight: '2%', width: '16%' }}  
                id="dt_nascimento"
                label="Data de Nascimento*"
                type="date"
                value={dt_nascimento}
                onChange={(e) => setDt_nascimento(e.target.value)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                variant="outlined"
                style={{ marginTop: 15, marginRight: '2%', width: '26%' }}
                id="cd_parentesco"
                value={cd_parentesco}
                label="Grau de Parentesco com Responsável*"
                onChange={(e) => setParentesco(e.target.value)}
                select>
                <MenuItem value="2">Cônjuge/companheiro</MenuItem>
                <MenuItem value="3">FIlho(a)</MenuItem>
                <MenuItem value="4">Neto(a)</MenuItem>
                <MenuItem value="5">Bisneto(a)</MenuItem>
                <MenuItem value="6">Pai/Mãe</MenuItem>
                <MenuItem value="7">Sogro(a)</MenuItem>
                <MenuItem value="8">Irmão/irmã</MenuItem>
                <MenuItem value="9">Genro/Nora</MenuItem>
                <MenuItem value="10">Não Parente</MenuItem>
                <MenuItem value="11">Outro</MenuItem>
              </TextField>

              <TextField
                  disabled={outroGrauOff}
                  margin="normal"
                  value={ds_parentesco_outro}
                  style={{ width: '32%' }}
                  id="ds_parentesco_outro"
                  label="Caso outro, qual?"
                  variant="outlined"
                  onChange={(e) => setOutroGrau(e.target.value)}
                />

              <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Dados Gerais</InputLabel>

              <FormControl style={{ marginTop: 15,  marginRight: '2%', width: '16%' }}  component="fieldset">
                <FormLabel component="legend">Possui alguma Deficiência?</FormLabel>
                <RadioGroup row value={st_deficiente} aria-label="st_deficiente" name="st_deficiente" onChange={(e) => setSt_deficiente(e.target.value)}>
                  <FormControlLabel value="Sim" control={<Radio />} label="SIM" />
                  <FormControlLabel value="Não" control={<Radio />} label="NÂO" />   
                </RadioGroup>
              </FormControl>

              <FormControl style={{ marginTop: 15, marginRight: '2%', width: '24%' }}  component="fieldset">
                <FormLabel component="legend">Precisa providenciar algum documento?</FormLabel>
                <RadioGroup row value={st_documento} aria-label="st_documento" name="st_documento" onChange={(e) => setSt_documento(e.target.value)}>
                  <FormControlLabel value="Sim" control={<Radio />} label="SIM" />
                  <FormControlLabel value="Não" control={<Radio />} label="NÂO" />   
                </RadioGroup>
              </FormControl>

              {/* {st_documento == 'Sim'? */}
                <TextField   
                  disabled={documentacaoOff}
                  margin="normal"
                  style={{ width: '56%' }}     
                  id="ds_documentacao_civil"
                  label="Caso Sim, quais?"
                  variant="outlined"
                  value={ds_documentacao_civil} 
                  onChange={(e) => setDs_documentacao_civil(e.target.value)}
                />
              {/* :null} */}

              <br></br><br></br>

              <Button onClick={handleClose} style={{ float: 'right', marginLeft:15, backgroundColor:"red" }} variant="contained" color="primary">
                Fechar
              </Button>
              
              <Button onClick={(editing===false)?enviar:editar} style={{ float: 'right' }}  variant="contained" color="primary">
                Cadastrar
              </Button>

              <br></br><br></br>

          </div>
 
        </Modal>
      </div>
    </div>
)}