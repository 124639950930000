import CrudView from 'components/Crud/CrudView';
import React from 'react';

export default function Equipes({...props}){
    return <div>
        <CrudView
            url={`/api/equipe`}
            columns={[
                {
                    title:'Nome',field:'nome'
                }
            ]}
            title='Equipes'
            fields={[
                {
                    field:'nome',label:'Nome',type:'text',grid:{xs:12}
                },{
                    field:'usuarios',label:'Usuários',type:'multi-select',grid:{xs:12},link:'/api/equipe/usuarios',nameKey:'name',dataKey:'id'
                },{
                    field:'obs',label:'Observações',type:'text',grid:{xs:12}, input: {multiline:true, rows:4}
                },
            ]}
            dynamic remoteLoad 
            canEdit canDelete topBar canAdd canSee 
        />
    </div>
}