import { InputLabel } from '@material-ui/core';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { MapCenter, MapZoom } from 'constants/Map';
import React from 'react';

export default function MapPoints({value,onChange,label,...props}){
    return <div>
        <InputLabel>{label}</InputLabel>
        <GoogleMap
            mapContainerStyle={{height:'30vh',width:'100%'}}
            zoom={MapZoom}
            center={MapCenter}
            onClick={(e)=>{
                onChange({
                    lat:e.latLng.lat(),
                    lng:e.latLng.lng()
                })
            }}
        >
            {value?.lat != null && value?.lng != null ? <Marker
                position={value}
            />:null}
        </GoogleMap>
    </div>
}