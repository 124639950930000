import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import moment from 'moment'
import { link } from '../../links/Links';
import { LaConFetch } from 'links/LaConFetch';

export default function Relatorio({...props}){
    const [start,setStart] = useState(moment())
    const [start2,setStart2] = useState(moment())
    const [local,setLocal] = useState(0)
    const [local2,setLocal2] = useState(0)
    const [relatorio,setRelatorio] = useState('')
    const [locais,setLocais] = useState([])
    const [relatorios,setRelatorios] = useState([
        {id:'principal',nome:'Relatório Mensal de Atendimento'},
        {id:'beneficios',nome:'Relatório de Benefícios Concedidos'},
        {id:'oficinas',nome:'Relatório de Oficinas'},
        {id:'perfil',nome:'Relatório de perfil de famílias'},
        {id:'estatistico',nome:'Relatório Estatistico de Atendimento'},
        {id:'gerencial',nome:'Relatórios Gerenciais'},
        {id:'monitoramento',nome:'Relatório de Monitoramento de Metas de Atendimentos'},
        {id:'tecnico',nome:'Relatórios Técnicos de Atendimento'},
        {id:'usuario',nome:'Relatório de Usuários Cadastrados por serviço'},
        {id:'atendimento',nome:'Relatório de Atendimento'},
    ])

    useEffect(()=>{
        LaConFetch(`/api/creas`,(r)=>{
            setLocais(r.items)
        },()=>{

        })
    },[])

    const onSubmit=()=>{
        
        fetch(`${link}/api/relatorio/generate?local=${local}&modo=${relatorio}&month=${start.month()}&year=${start.year()}`,{
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Accept': 'application/json'
            },
        })
        .then( res => res.blob() )
        .then( blob => {
            var file = window.URL.createObjectURL(blob);
            //window.location.assign(file);
            window.open(file)
        });
    }

    const onGenerateXML=()=>{
        fetch(`${link}/api/xml/mds?creas=${local2}&month=${start2.month()}&year=${start2.year()}`,{
            headers:{
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Accept': 'application/json'
            },
        }).then(res=>res.blob()).then(blob=>{
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `export.xml`;
            a.click();
        })
    }

    return <div>
        <div className="bg-primary" style={{backgroundColor:'rgb(37, 85, 39)',padding:'10px'}}>
            <div className="clearfix">
                <h4 style={{float:'left',margin:'3px 0'}}>Relatórios</h4>
            </div>
        </div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Paper style={{padding:10,margin:5}}>
                <h5>Gerar Relatórios <b>PDF</b></h5>
                <Grid container spacing={1}>
                    <Grid item md={4}>
                        <FormControl
                            fullWidth margin='normal'
                        >
                            <InputLabel>Modelo de relatório</InputLabel>
                            <Select
                                value={relatorio} fullWidth margin='normal'
                                onChange={(e)=>{setRelatorio(e.target.value)}}
                            >
                                <MenuItem value="">
                                    <em>Selecione um modelo de relatório</em>
                                </MenuItem>
                                {relatorios.map(item=><MenuItem value={item.id}>{item.nome}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4}>
                        <FormControl
                            fullWidth margin='normal'
                        >
                            <InputLabel>Local</InputLabel>
                            <Select
                                value={local} fullWidth margin='normal'
                                onChange={(e)=>{setLocal(e.target.value)}}
                            >
                                <MenuItem value="">
                                    <em>Selecione um local</em>
                                </MenuItem>
                                {locais.map(item=><MenuItem value={item.id}>{item.nome}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4}>
                        <KeyboardDatePicker
                            value={start} onChange={setStart}
                            format="DD/MM/YYYY" label="Mês de Referência"
                            fullWidth margin='normal'
                        />
                    </Grid>
                </Grid>
                <Button color='primary' variant='contained' onClick={onSubmit}>
                    Gerar Relatório
                </Button>
            </Paper>
            <Paper style={{padding:10,margin:5}}>
                <h5>Gerar <b>XML</b> para exportar</h5>
                <Grid container spacing={1}>
                    {/*<Grid item md={4}>
                        <FormControl
                            fullWidth margin='normal'
                        >
                            <InputLabel>Modelo de relatório</InputLabel>
                            <Select
                                value={relatorio} fullWidth margin='normal'
                                onChange={(e)=>{setRelatorio(e.target.value)}}
                            >
                                <MenuItem value="">
                                    <em>Selecione um modelo de relatório</em>
                                </MenuItem>
                                {relatorios.map(item=><MenuItem value={item.id}>{item.nome}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>*/}
                    <Grid item md={4}>
                        <FormControl
                            fullWidth margin='normal'
                        >
                            <InputLabel>Local</InputLabel>
                            <Select
                                value={local2} fullWidth margin='normal'
                                onChange={(e)=>{setLocal2(e.target.value)}}
                            >
                                <MenuItem value="">
                                    <em>Selecione um local</em>
                                </MenuItem>
                                {locais.map(item=><MenuItem value={item.id}>{item.nome}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4}>
                        <KeyboardDatePicker
                            value={start2} onChange={setStart2}
                            format="DD/MM/YYYY" label="Mês de Referência"
                            fullWidth margin='normal'
                        />
                    </Grid>
                </Grid>
                <Button color='primary' variant='contained' onClick={onGenerateXML}>
                    Gerar Relatório
                </Button>
            </Paper>
        </MuiPickersUtilsProvider>
    </div>
}