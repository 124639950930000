import { _MaterialTableLocalization } from 'links/TableConstant';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import {Button, Card, CardHeader, CardContent, Grid, TextField, MenuItem, CircularProgress, Backdrop} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {link} from '../links/Links';
import { LaConFetch } from 'links/LaConFetch';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import 'moment/locale/pt-br';
import swal from 'sweetalert';
import { actionIsAllowed } from 'helpers/Permissions';

const useStyles = makeStyles((theme) => ({
  
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function AlmoxTransferir(){
    const classes = useStyles();

    const [selectedRow, setSelectedRow] = useState(null);

    const [produtos, setProdutos] = useState([]);
    const [produtosAll, setProdutosAll] = useState([]);
    const [tiposMovimentos,setTiposMovimentos] = useState([])

    const [allowed,setAllowed] = useState({
        add: false, edit:false, delete:false
    })

    const [movimentos, setMovimentos] = useState([]);

    const [sending,setSending] = useState(false)
    
    const [open, setOpen] = React.useState(false);
    const [tipo, setTipo] = React.useState('');

    const [destino,setDestino] = useState(0)
    const [locals,setLocals] = useState([])

    const [adicionar,setAdicionar] = useState({
        produto: '',
        qtd:''
    });

    const [registro,setRegistro] = useState({
        anexo: '',
        observacao:'',
        vencimento: new Date(),
    });

    useEffect(()=>{
        setAllowed({
            edit: actionIsAllowed('tran','update'),
            delete: actionIsAllowed('tran','delete'),
            add:actionIsAllowed('tran','create'),
        })
    },[])

    const loadMovimentos=()=>{
        LaConFetch(`/api/almoxarifado/transferir/index`,(r)=>{
            if(r.success){
                setMovimentos(r.items)
            }
        },()=>{})
    }

    useEffect(()=>{
        loadMovimentos()
    },[])

    useEffect(()=>{
        LaConFetch(`/api/almoxarifado/produto`,(r)=>{
            if(r.success){
                let hold = r.items.map(item=>{
                    return {
                        produto_id: item.id,
                        produto: item.nome
                    }
                })
                setProdutosAll(hold)
            }
        },()=>{})
    },[])

    useEffect(()=>{
        LaConFetch(`/api/creas`,(r)=>{
            if(r.success){
                setLocals(r.items)
            }
        },()=>{})
    },[])

    const handleAdicionar=(field,value)=>{
        let hold = {...adicionar}
        hold[field] = value
        setAdicionar(hold)
    }
    const addProdutos = () => {
        let hold = [...produtos]
        hold.push({
            produto_id: adicionar.produto.produto_id,
            produto: adicionar.produto.produto,
            vencimento: moment(adicionar.vencimento).format('YYYY-MM-DD'),
            qtd: adicionar.qtd,
        })
        setProdutos(hold)
        setAdicionar({
            produto: '',
            qtd:'',
            vencimento: new Date(),
        })
    }

    const handleOpen = () => {
        //clear();
        setOpen(true);
    };

    const handleClose = () => {
        //clear();
        setOpen(false);
    };

    
    const handleRegistro=(field,value)=>{
        let hold = {...registro}
        hold[field] = value
        if (field == 'tipo') {
            value = parseInt(value)
            setTipo(value)
        }
        setRegistro(hold)
    }

    const enviar = () => {
        setSending(true)
        let form = {
            movimentos:produtos,
            obs:registro.observacao,
            destino_id: destino,
        }

        LaConFetch(`/api/almoxarifado/transferencia/new`,(r)=>{
            if(r.success){
                if(r.alert){
                    swal("Transferência realizada!",r.message, "warning");
                }else{
                    swal("Transferência realizada com sucesso!","", "success");
                }
                loadMovimentos()
                setOpen(false)
            }else{
                swal("Houve um erro!",r.message,"erro");
            }
            setSending(false)
        },()=>{
            swal("Houve um erro!","Server Error","erro");
            setSending(false)
        },form)
    }
    
    return (<MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="justify-content-center ml-5">
        <Backdrop style={{zIndex:99}} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <div className="bg-primary" style={{backgroundColor:'rgb(37, 85, 39)',padding:'10px'}}>
          <div className="clearfix">
          <h4 style={{float:'left',margin:'3px 0'}}>Transferências</h4>
          {(!open) ? allowed.add ? <Button onClick={handleOpen} style={{ float: 'right' }}  variant="contained" color="primary">
            Nova Transferência
          </Button>: null : (
          <Button onClick={handleClose} style={{ float: 'right' }}  variant="contained" color="warning">
            Lista de Transferências
          </Button>
          )}
          </div>
        </div>
        
        {(!open) ? (
        <div>
          <MaterialTable 
                columns={[
                    { title: 'Cód', field: 'id', headerStyle: {fontWeight: 'bold'} },
                    { title: 'Data', field: 'created_at',render:(data)=>moment(data.created_at,'YYYY-MM-DD').format('DD/MM/YYYY'), headerStyle: {fontWeight: 'bold'} },
                    { title: 'Origem', field: 'origem.nome', headerStyle: {fontWeight: 'bold'} },
                    { title: 'Destino', field: 'destino.nome', headerStyle: {fontWeight: 'bold'} },
                    { title: 'Operador', field: 'user.name', headerStyle: {fontWeight: 'bold'} },
                ]}
                data={movimentos}
                options={{
                    actionsColumnIndex: -1,
                    rowStyle: rowData => ({
                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                    })
                }}
                localization={_MaterialTableLocalization}
                title="Lista de Transferências" />
        </div>) : null}

        {(open) ? (<div>
        <Card style={{margin:'5px'}}>
            <CardContent>
                  
              <Grid container spacing={3}>

              <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 15 }}
                    id="tipo" 
                    value={destino}
                    label="Destino*"  
                    onChange={(e) => setDestino(e.target.value)}
                    select
                  >
                    {locals.map(item=><MenuItem value={item.id}>{item.nome}</MenuItem>)}
                  </TextField>
              </Grid>

                <Grid item lg={5}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 15 }}
                    id="produto_id" 
                    value={adicionar.produto}
                    label="Produto*"  
                    onChange={(e) => handleAdicionar('produto',e.target.value)}
                    select>
                    {produtosAll.map(item =>
                      <MenuItem value={item}>{item.produto}</MenuItem>
                    )}          
                  </TextField>
                </Grid>
                <Grid item lg={3}>
                    <KeyboardDatePicker
                        clearable
                        value={adicionar.vencimento}
                        onChange={date => handleAdicionar('vencimento',date)}
                        minDate={new Date()} label="Vencimento"
                        format="DD/MM/yyyy" margin='normal'
                        inputVariant='outlined' fullWidth
                    />
                </Grid>
                <Grid item lg={2}>
                  <TextField   
                    margin="normal"
                    fullWidth
                    id="qtd"
                    label="Quantidade"
                    variant="outlined"
                    value={adicionar.qtd} 
                    onChange={(e) => handleAdicionar('qtd',e.target.value)}
                  />
                </Grid>
                <Grid item lg={1}>
                  <Button variant="contained" size="large" color="primary" style={{padding:'17px',marginTop:'15px'}} onClick={()=>addProdutos()}>
                    Adicionar
                  </Button>
                </Grid>

                <Grid item lg={12}>
                <MaterialTable 
                    columns={[
                      { title: 'Produto', field: 'produto', headerStyle: {fontWeight: 'bold'} },
                      { title: 'Quantidade', field: 'qtd', headerStyle: {fontWeight: 'bold'} },
                    ]}
                    data={produtos}
                    actions={[
                        {
                          icon: 'edit',
                          tooltip: 'Editar',
                          onClick: (e,data) => {}
                        },
                    ]}
                    components={{Container:'div'}}
                    options={{
                        actionsColumnIndex: -1,
                        rowStyle: rowData => ({
                          backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                        }),
                        search: false,
                        showTitle: false,
                        toolbar: false
                    }}
                    localization={_MaterialTableLocalization}
                    />
                </Grid>

                <Grid item lg={12}>
                    <TextField   
                    margin="normal"
                    fullWidth    
                    id="observacao"
                    label="Observação"
                    variant="outlined"
                    value={registro.observacao} 
                    onChange={(e) => handleRegistro('observacao',e.target.value)}
                    multiline='true'
                    rows='4'
                    />
                </Grid>

              </Grid>
              <br></br><br></br>

              <Button onClick={enviar} style={{ float: 'right' }}  variant="contained" color="primary">
                Enviar
              </Button>

              <br></br><br></br>
            </CardContent>
        </Card>
    </div>):null}

   </div>

   </MuiPickersUtilsProvider>)
}