import { Button, Grid, Modal, Paper, TextField } from '@material-ui/core';
import { LaConFileUpload } from 'links/LaConFetch';
import { link } from 'links/Links';
import { transpileModule } from 'typescript';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { LaConFetch } from 'links/LaConFetch';

export default function CardResponsavel({familiaId,...props}){
    const [askPhoto,setAskPhoto] = useState(false)
    const [responsavel, setResponsavel] = useState({})
    const [file,setFile] = useState(null)
    const [sending,setSending] = useState(false)

    useEffect(()=>{
        if(familiaId>0){
            loadResponsavel()
        }
    },[familiaId])

    const loadResponsavel=()=>{
        setSending(true);
        LaConFetch(`/api/resultResponsavel/${familiaId}`, result => {
            if(result.dados !== null){
                setResponsavel(result.dados[0])
                setSending(false);
            }
        },() => {
            setSending(false)
        })
    }

    const submitFile=()=>{
        setSending(true)
        setAskPhoto(false);
        let formFile = [{
            name:'anexo',
            file: file,
        }]
        let formStuff = [];
        LaConFileUpload(`/api/responsavel/add-foto/${responsavel.pessoa.id}`,formFile,formStuff,(r)=>{
            if(r.success){
                loadResponsavel(familia_id)
                swal('Foto adiconada com sucesso!','','success')
                setFile(null)
            }
            setSending(false)
        },()=>{
            setSending(false)
        })
    }
    return <>
        <Grid container spacing={3}>
            <Grid item lg={4}>
                <div>
                    <div>
                    <img 
                        src={responsavel?.pessoa?.foto ? `${link}${responsavel.pessoa.foto}`:require('assets/img/default-avatar.png')} 
                        style={{height:100,width:'100%',objectFit:'contain'}}
                    />
                    </div>
                    <div>
                    <Button variant="contained" size="small" color="primary" style={{marginTop:'5px'}} onClick={()=>setAskPhoto(true)}>
                        Adicionar Foto
                    </Button>
                    </div>
                </div>
            </Grid>
            <Grid item lg={8}>
                {(responsavel?.pessoa?.nm_pessoa) ? <>
                    <h5 style={{margin:'2px 0',fontSize:'16px',fontWeight:'bold'}}>{responsavel.pessoa.nm_pessoa}</h5>
                </> : null}
                <div style={{fontSize:'14px'}}>
                    {responsavel?.responsavel?.nr_cpf ? <><strong>CPF:</strong> {responsavel.responsavel.nr_cpf} <br/></> : null}
                    {responsavel?.responsavel?.nr_nis ? <><strong>NIS:</strong> {responsavel.responsavel.nr_nis} <br/></> : null}
                    {responsavel?.cadastro ? <><strong>Data Cadastro:</strong> {responsavel.cadastro}<br/></>:null}
                    {responsavel?.ultima_visita ? <><strong>Último Atendimento:</strong> {responsavel.ultima_visita}<br/></>:null}
                    {responsavel?.tecnico_inicial ? <><strong>Técnico de Cadastro:</strong> {responsavel.tecnico_inicial}<br/></>:null}
                    {responsavel?.ultimo_tecnico ? <><strong>Técnico do Último Atendimento:</strong> {responsavel.ultimo_tecnico}</>:null}
                </div>
            </Grid>
        </Grid>
        <Modal open={askPhoto} onClose={()=>{setAskPhoto(false);setFile(null)}}>
            <div className='container' style={{marginTop:50}}>
            <Paper style={{padding:20}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <b>Adicionar foto ao responsável</b>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type='file' fullWidth margin='normal' variant='outlined' label="Arquivo" inputProps={{accept:'image/*'}}
                            onChange={(e)=>{setFile(e.target.files[0])}} InputLabelProps={{shrink:transpileModule}}
                        />
                    </Grid>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" onClick={submitFile}>
                            Enviar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            </div>
        </Modal>
    </>
}