import { Paper, Grid, TextField, Select, MenuItem, FormControl, InputLabel, Button, CircularProgress, Backdrop } from '@material-ui/core';
import { LaConFetch } from 'links/LaConFetch';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import Permissao from 'views/Perfis/Permissao';

export default function EditarPerfil({onClose,edit,novo,...props}){
    const [telas,setTelas] = useState([])
    const [prontuarios,setProntuarios] = useState([])
    const [permissaoTela,setPermissaoTela] = useState({})
    const [permissaoProntuario,setPermissaoProntuario] = useState({})
    const [nome,setNome] = useState('')
    const [permissao,setPermissao] = useState('geral')
    const [loading,setLoading] = useState(false)

    const loadParameters=()=>{
        setLoading(true)
        LaConFetch(`/api/perfis/telas`,(r)=>{
            if(r.success){
                setTelas(r.data)
            }
            setLoading(false)
        },()=>{})

        LaConFetch(`/api/perfis/prontuario`,(r)=>{
            if(r.success){
                setProntuarios(r.data)
            }
        },()=>{})
    }

    const loadData=()=>{
        LaConFetch(`/api/perfis/show/${edit}`,(r)=>{
            if(r.success){
                setNome(r.data.nome)
                setPermissaoTela(r.data.telas)
                setPermissaoProntuario(r.data.prontuarios)
            }
        },()=>{})
    }

    useEffect(()=>{
        if(edit>0){
            loadData()
        }else if(novo){
            setNome('')
            setPermissaoProntuario([])
            setPermissaoTela([])
        }
    },[edit,novo])

    useEffect(()=>{
        loadParameters()
    },[])

    const updatePermissoes=(action,id)=>{
        let hold = {...permissaoTela}
        if(!hold[id]){
            hold[id] = {
                id: id,
                see: false,
                create: false,
                update: false,
                delete: false,
                enabled: action!='enabled',
            }
        }
        hold[id][action] = hold[id][action] ? false:true
        if(action == 'enabled'){
            hold[id]['see'] = hold[id][action];
            hold[id]['create'] = hold[id][action];
            hold[id]['update'] = hold[id][action];
            hold[id]['delete'] = hold[id][action];
        }
        setPermissaoTela(hold)
    }
    const updateProntuarios=(action,id)=>{
        let hold = {...permissaoProntuario}
        if(!hold[id]){
            hold[id] = {
                id: id,
                see: false,
                create: false,
                update: false,
                delete: false,
                enabled: action!='enabled',
            }
        }
        hold[id][action] = hold[id][action] ? false:true
        if(action == 'enabled'){
            hold[id]['see'] = hold[id][action];
            hold[id]['create'] = hold[id][action];
            hold[id]['update'] = hold[id][action];
            hold[id]['delete'] = hold[id][action];
        }
        setPermissaoProntuario(hold)
    }

    const onSave=()=>{
        setLoading(true)
        let form = {
            nome:nome,
            telas:permissaoTela,
            prontuarios:permissaoProntuario,
        }

        let link = `/api/perfis/create`
        if(edit > 0){
            link = `/api/perfis/update/${edit}`
        }
        LaConFetch(link,(r)=>{
            if(r.success){
                swal(`Perfil salvo com sucesso!`,"", "success");
                onClose(true)
            }else{
                swal("Oops",r.message, "error");
            }
            setLoading(false)
        },()=>{
            swal("Oops","Houve um erro, tente novamente", "error");
            setLoading(false)
        },form)
    }

    return <div>
        <Backdrop style={{zIndex:99}} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Paper style={{padding:5,margin:5}}>
            <p style={{paddingBottom:5, fontSize:16,fontWeight:"bold"}}>{novo ? 'Novo Perfil':`Editar Perfil #${edit}`}</p>
            <TextField
                value={nome} onChange={(e)=>setNome(e.target.value)}
                margin='normal' label='Nome' fullWidth
            />
            <FormControl fullWidth margin="normal">
                <InputLabel>Permissões</InputLabel>
                <Select
                    value={permissao} onChange={(e)=>setPermissao(e.target.value)}
                >
                    <MenuItem value={'geral'}>Geral</MenuItem>
                    <MenuItem value={'prontuario'}>Prontuário</MenuItem>
                </Select>
            </FormControl>
            <div style={{marginBottom:5,marginTop:5}}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item xs={3}>
                        <b>Área</b>
                    </Grid>
                    <Grid item xs={3}>
                        <b>Criar</b>
                    </Grid>
                    <Grid item xs={3}>
                        <b>Atualizar</b>
                    </Grid>
                    <Grid item xs={3}>
                        <b>Deletar</b>
                    </Grid>
                </Grid>
            </div>
            {permissao=='geral'?<div>
                {telas.map((tela,i)=><Permissao 
                    key={tela.id} tela={tela} selected={permissaoTela[tela.id]}
                    updateSelected={updatePermissoes}
                />)}
            </div>: permissao=='prontuario' ? <div>
                {prontuarios.map((area,i)=><Permissao 
                    key={area.id} tela={area} selected={permissaoProntuario[area.id]}
                    updateSelected={updateProntuarios}
                />)}
            </div>:null}
            <div style={{display:'flex',justifyContent:"end"}}>
                <Button color='primary' variant='contained' onClick={onSave}>
                    Salvar
                </Button>
            </div>
        </Paper>
    </div>
}