import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';

export default function Permissao({tela,selected,updateSelected,...props}){
    return <div>
        <Grid container spacing={1} alignItems='center' justify='center'>
            <Grid item xs={3}>
                <FormControlLabel
                        control={<Checkbox 
                            checked={selected?.enabled ? true:false} value={selected?.enabled ? true:false}
                            onChange={()=>updateSelected('enabled',tela.id)}
                        />}
                        label={tela.nome}
                    />
            </Grid>
            {!tela.single ? <>
                <Grid item xs={3}>
                    <Checkbox
                        checked={selected?.create ? true:false} value={selected?.create ? true:false}
                        onChange={()=>updateSelected('create',tela.id)} disabled={!tela?.create}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Checkbox
                        checked={selected?.update ? true:false} value={selected?.update ? true:false}
                        onChange={()=>updateSelected('update',tela.id)} disabled={!tela?.update}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Checkbox
                        checked={selected?.delete ? true:false} value={selected?.delete ? true:false}
                        onChange={()=>updateSelected('delete',tela.id)} disabled={!tela.delete}
                    />
                </Grid>
            </>:<Grid item xs={9} >
                <div style={{display:'flex',justifyContent:'center'}}>
                    <FormControlLabel
                        control={<Checkbox 
                            checked={selected?.see ? true:false} value={selected?.see ? true:false}
                            onChange={()=>updateSelected('see',tela.id)}
                        />}
                        label="Acesso"
                    />
                </div>
            </Grid>}
        </Grid>
    </div>
}