import React, { Component, useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import NotificationSystem from "react-notification-system";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import { style } from "variables/Variables.jsx";

import dashboardRoutes from "routes.js";

import image from "assets/img/sidebar-3.jpg";
import { SelfContext } from "provider/SelfContext";
import { SwitchRoutes } from "helpers/Permissions";

function Admin({...props}) {
  const [_notificationSystem,set_notificationSystem] = useState(null)
  const [_image,set_image] = useState(image)
  const [color,setColor] = useState('black')
  const [hasImage,setHasImage] = useState(true)
  const [fixedClasses,setFixedClasses] = useState("dropdown show-dropdown open")
  
  const {token,loading} = useContext(SelfContext)
  const {history} = useHistory()

  const getBrandText = path => {
    const finalRoutes = dashboardRoutes();
    for (let i = 0; i < finalRoutes.length; i++) {
      if (
        props.location.pathname.indexOf(
          finalRoutes[i].layout + finalRoutes[i].path
        ) !== -1
      ) {
        return finalRoutes[i].name;
      }
    }
    return "SADS";
  };
  if(!token && !loading){
    return <Redirect
      to={'/'}
    />
  }
    return (
      <div className="wrapper">
        {/*<NotificationSystem ref="notificationSystem" style={style} />*/}
        <Sidebar {...props} 
        // image={this.state.image}
        color={color}
        hasImage={hasImage}/>
        <div id="main-panel" className="main-panel">
          <AdminNavbar
            {...props}
            brandText={getBrandText(props.location.pathname)}
          />
          <Switch>
            <SwitchRoutes/>
          </Switch>
          <Footer />
        </div>
      </div>
    );
}

export default Admin;
