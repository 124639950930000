import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import moment from 'moment'
import { link } from 'links/Links';

export default function Carteira({responsavel,...props}){
    return <div style={{margin:20}}>
        <Paper style={{padding:20}}>
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <div className='text-center'>
                            <b>Secretária De Assistência e Desenvolvimento Social</b>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>
                            <img src={responsavel && responsavel.pessoa && responsavel.pessoa.foto ? `${link}/storage/${responsavel.pessoa.foto}`:require('assets/img/default-avatar.png')} style={{height:100,width:100}}/>
                        </div>
                    </Grid>
                    <Grid item xs={8}>
                        <div>
                            <div>
                                <b>Nome: </b>
                                {responsavel && responsavel.pessoa && responsavel.pessoa.nm_pessoa ? responsavel.pessoa.nm_pessoa:''}
                            </div>
                            <div>
                                <b>Data de Nascimento: </b>
                                {responsavel && responsavel.pessoa && responsavel.pessoa.dt_nascimento ? moment(responsavel.pessoa.dt_nascimento,'YYYY-MM-DD').format('DD/MM/YYYY'):''}
                            </div>
                            <div>
                                <b>Nº NIS: </b>
                                {responsavel && responsavel.pessoa && responsavel.responsavel.nr_nis ? responsavel.responsavel.nr_nis:''}
                            </div>
                            <div>
                                <b>CPF: </b>
                                {responsavel && responsavel.pessoa && responsavel.responsavel.nr_cpf ? responsavel.responsavel.nr_cpf:''}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            Esta carteira é de uso intrasferivel
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Paper>
    </div>
}