import { Backdrop, Button, CircularProgress, Grid, Modal, Paper } from '@material-ui/core';
import { GoogleMap, Marker } from '@react-google-maps/api';
import AgeGraph from 'components/Graph/AgeGraph';
import SexoGraph from 'components/Graph/SexoGraph';
import StatsCard from 'components/StatsCard/StatsCard';
import { MapCenter } from 'constants/Map';
import { MapZoom } from 'constants/Map';
import { LaConFetch } from 'links/LaConFetch';
import React, { useEffect, useState } from 'react';

export default function PerLocal({local=false,...props}){
    const [locais,setLocais] = useState([])
    const [modal,setModal] = useState(0)
    const [points,setPoints] = useState([])
    const [greetings,setGreetings] = useState({})
    const [counters,setCounters] = useState([])
    const [loading,setLoading] = useState(true)

    const loadData = ()=>{
        LaConFetch(`/api/dashboard/global${local ? `?local=${local}`:''}`,(r)=>{
            if(r.success){
                setLocais(r.locais)
                setCounters(r.counters)
                setGreetings(r.greetings)
                setPoints(r.points)
            }
            setLoading(false)
        },()=>{
            setLoading(false)
        })
    }

    useEffect(()=>{
        loadData()
    },[local])

    return <div>
        <Backdrop style={{zIndex:99}} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={3}>
            <Grid item xs={9}>
                <Grid container spacing={3}>
                    {counters.map(item=><Grid item xs={3}>
                        <StatsCard
                            bigIcon={<i className={item.icon} />} statsText={item.nome} key={item.nome}
                            statsIcon={<i className="fa fa-refresh" />} statsIconText="Atualizado agora"
                            statsValue={item.value}
                        />
                    </Grid>)}
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Paper style={{padding:10}}>
                    <div style={{textAlign:'center'}}>
                        <b>Bem-vindo, {greetings.name}</b>
                    </div>
                    <div style={{textAlign:'center'}}>
                        Dia: {greetings.date}
                    </div>
                    <div style={{textAlign:'center'}}>
                        Sertãozinho/SP
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <div style={{height:'30vh'}}>
                    <SexoGraph/>
                </div>
                <div style={{height:'30vh'}}>
                    <AgeGraph/>
                </div>
            </Grid>
            <Grid item xs={8}>
                <GoogleMap
                    mapContainerStyle={{height: '60vh',width:'100%'}}
                    zoom={MapZoom}
                    center={MapCenter}
                >
                    {!local ? <>
                    {locais.map(item=><Marker
                        position={{lat:item.lat,lng:item.lng}} key={item.id}
                        icon={require(`assets/img/${item.tipo}.png`)}
                    />)}
                    </>:null}
                </GoogleMap>
            </Grid>
            {!local ? <>
                {locais.map(item=><Grid item xs={3}>
                    <Paper style={{padding:10,backgroundColor:'#e83a1a',color:'white'}}>
                        <div style={{textAlign:'center'}}>
                            <b>{item.nome}</b>
                        </div>
                        <Button onClick={()=>setModal(item.id)}>
                            <i className='fas fa-plus' style={{color:'white'}}/>
                        </Button>
                    </Paper>
                </Grid>)}
                <Modal open={modal>0} onBackdropClick={()=>setModal(0)}>
                    <div className='container' style={{marginTop:10}}>
                        <Paper style={{padding:5}}>
                            <PerLocal local={modal}/>
                        </Paper>
                    </div>
                </Modal>
            </>:null}
        </Grid>
    </div>
}