import VerifyLife from 'layouts/VerifyLife';
import React, { Component } from 'react';


export const SelfContext = React.createContext();


export default class SelfProvider extends Component {
    state = {
        token: '', loading: true,
        updateToken:(token)=>{
            sessionStorage.setItem('token',token)
            this.setState({
                token: token,
                loading: false,
            });
        },
    }

    componentDidMount(){
        let token = sessionStorage.getItem('token')
        this.setState({token:token,loading:false})
    }

    render() {
        return (<SelfContext.Provider value={this.state}>
            <VerifyLife token={this.state.token} updateToken={this.state.updateToken}/>
            {this.props.children}
        </SelfContext.Provider>);
    }
}