import { Chip, Input, TextField } from '@material-ui/core';
import React, { useState } from 'react';

export default function ArrayText({value,onChange,...props}){
    const [text,setText] = useState('')
    const onChangeText=(e)=>{
        setText(e.target.value.replace(',',''))
    }
    const _handleKeyPress = (e) => {
        if (e.key === 'Backspace' && e.target.value.length===0 && text.length>0){
            const chipData = [...value];
            let first = chipData.pop();
            onChange(chipData);
        }
        if ((e.key === 'Enter' || e.key === ',') && e.target.value.length>0) {
            setText('')
            onAdd(text);
        }
      };
    const onRemove=(pos)=>{
        let hold = [...value]
        hold.splice(pos,1)
        onChange(hold)
    }
    const onAdd=(nextValue)=>{
        let hold = [...value]
        hold.push(nextValue)
        onChange(hold)
    }
    return <div style={{width:'100%'}}>
        <TextField
            value={text} onChange={onChangeText}
            onKeyUp={_handleKeyPress}
            {...props}
        />
        {value.map((v,i)=>{
            return <Chip 
                key={v} label={v}
                onDelete={()=>onRemove(i)}
            />
        })}
    </div>
}