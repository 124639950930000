import CrudView from 'components/Crud/CrudView'
import { actionIsAllowed } from 'helpers/Permissions'
import React, { useEffect, useState } from 'react'

export default function CategoriaAlmoxarifado({...props}){
    const [allowed,setAllowed] = useState({
        add: false, edit:false, delete:false
    })

    useEffect(()=>{
        setAllowed({
            edit: actionIsAllowed('cate','update'),
            delete: actionIsAllowed('cate','delete'),
            add:actionIsAllowed('cate','create'),
        })
    },[])

    return <div>
        <CrudView
            url={'/api/almoxarifado/categoria'}
            columns={[
                {field:'nome',title:'nome'}
            ]}
            title='Categoria'
            canEdit={allowed.edit} canDelete={allowed.delete} canAdd={allowed.add}
            fields={[
                {
                    field:'nome',label:'Nome',type:'text',grid:{xs:12}
                },{
                    field:'descricao',label:'Descrição',type:'text',input:{rows:4,multiline:true},grid:{xs:12}
                }
            ]}
        />
    </div>
}