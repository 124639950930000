import React, { useEffect, useState } from 'react';
import { _materialTableIcons, _materialTableLocalization } from 'constants/MaterialConstant';
import MaterialTable from 'material-table';
import { LaConFetch } from 'links/LaConFetch';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import EditarPerfil from './EditarPerfil';
import { actionIsAllowed } from 'helpers/Permissions';

export default function ListaPerfil({...props}){
    const [perfis,setPerfis] = useState([])
    const [edit,setEdit] = useState(0)
    const [novo,setNovo] = useState(false)
    const [success,setSuccess] = useState(false)
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState({has:false,message:''})
    const [allowed,setAllowed] = useState({
        add: false, edit:false, delete:false
    })
    const history = useHistory()

    useEffect(()=>{
        setAllowed({
            edit: actionIsAllowed('carg','update'),
            delete: actionIsAllowed('carg','delete'),
            add:actionIsAllowed('carg','create'),
        })
    },[])

    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        setLoading(true)
        LaConFetch(`/api/perfis/index`,(r)=>{
            if(r.success){
                setPerfis(r.data)
                setSuccess(true)
            }else{
                setError({has:true,message:r.message})
            }
            setLoading(false)
        },()=>{
            setError({has:true,message:'Erro ao carregar os dados'})
            setLoading(false)
        })
    }

    const onClose=(reload=false)=>{
        setEdit(0)
        setNovo(false)
        if(reload) loadData()
    }

    const handleOpen=()=>{
        setNovo(true)
    }

    const onDelete=(id)=>{
        setLoading(true)
        let form ={}
        LaConFetch(`/api/perfis/delete/${id}`,(r)=>{
            setLoading(false)
            if(r.success){
                swal("Perfil removido com sucesso!","", "success");
            }else{
                swal("Oops","Houve um erro ao remover o perfil", "error");
            }
            loadData()
        },()=>{},form)
    }

    const onAskDelete=(id,title)=>{
        swal(`Tem certeza que deseja remover o perfil ${title}?`,"Os usuários ficaram sem acesso até o perfil ser atualizado.", {
            buttons: {
              remover: "Remover",
              cancel: "Sair"
            },
          })
          .then((value) => {
            switch (value) {
              case "remover":
                onDelete(id)
                break;
            }
          })
    }

    let actions=[]

    if(allowed.edit){
        actions.push({
            icon:'edit',
            tooltip:'Editar',
            onClick:(e,data)=>{
                setEdit(data.id)
                setNovo(false)
            }
        })
    }
    if(allowed.delete){
        actions.push({
            icon:'delete',
            tooltip:'Remover',
            onClick:(e,data)=>{
                onAskDelete(data.id,data.nome)
            }
        })
    }

    return <div className="justify-content-center ml-5">
        <div className="bg-primary" style={{backgroundColor:'rgb(37, 85, 39)',padding:'10px'}}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <h4 style={{margin: '3px 0px'}}>Perfis de Acesso</h4>
                {novo || edit > 0 ? <Button onClick={()=>onClose(false)} variant="contained" color="primary">
                    Voltar
                </Button>: allowed.add ? <Button onClick={handleOpen} variant="contained" color="primary">
                    Novo Perfil
                </Button>:null}
            </div>
        </div>
        {novo || edit > 0 ? <EditarPerfil
            onClose={onClose} novo={novo} edit={edit}
        />:<MaterialTable
            icons={_materialTableIcons} localization={_materialTableLocalization}
            title='Perfis' data={perfis} isLoading={loading}
            columns={[
                { title: 'Nome', field: 'nome' },
            ]}
            actions={actions}
            options={{
                actionsColumnIndex: -1, pageSize:10,
            }}
        />}
    </div>
}