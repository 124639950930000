
export const tipoAcessoAtendimentos = [
    {id:1,title:"Por demanda espontânea",other:false},
    {id:2,title:"Em decorrência de Busca Ativa realizada pela equipe da unidade",other:false},
    {id:3,title:"Em decorrência de encaminhamento realizado por outros serviços/unidades da Proteção Social Básica",other:false},
    {id:4,title:"Em decorrência de encaminhamento realizado por outros serviços/unidades da Proteção Social Especial",other:false},
    {id:5,title:"Em decorrência de encaminhamento realizado pela área de Saúde",other:false},
    {id:6,title:"Em decorrência de encaminhamento realizado pela área de Educação",other:false},
    {id:7,title:"Em decorrência de encaminhamento realizado por outras políticas setoriais",other:false},
    {id:8,title:"Em decorrência de encaminhamento pelo Conselho Tutelar",other:false},
    {id:9,title:"Em decorrência de encaminhamento pelo Poder Judiciário",other:false},
    {id:10,title:"Em decorrência de encaminhamento pelo Sistema de Garantia de Direitos (Defensoria Pública, Ministério Público, Delegacias)",other:false},
    {id:11,title:"Outros encaminhamentos",other:true},
]

export const tipoAtendimento = [
    {id:1,title:'Atendimento socioassistencial individualizado',other:false},
    {id:2,title:'Atendimento em atividade coletiva de caráter continuado',other:false},
    {id:3,title:'Participação em atividade coletiva de caráter não continuado',other:false},
    {id:4,title:'Cadastramento / Atualização Cadastral',other:false},
    {id:5,title:'Acompanhamento de MSE',other:false},
    {id:6,title:'Solicitação / Concessão de Benefício Eventual',other:false},
    {id:7,title:'Visita Domiciliar',other:false},
    {id:9,title:'Outros',other:false},
]