import { Backdrop, CircularProgress, Grid, Button, MenuItem, Select, Checkbox, ListItemText, Input, TextField, Chip, InputLabel, FormControl  } from '@material-ui/core';
import { LaConFetch } from 'links/LaConFetch';
import React, { useEffect, useState } from 'react';
import AreaEstudoSocial from 'components/FormInputs/AreaEstudoSocial'
import swal from 'sweetalert';

export default function FormularioExtra({familiaId,...props}){
    const [perguntas,setPerguntas] = useState([])
    const [respostas,setRespostas] = useState({})
    const [novaResposta,setNovaResposta] = useState({})
    const [sending,setSending] = useState(false)

    useEffect(()=>{
        if(familiaId>0){
            loadData()
        }
    },[familiaId])

    const loadData=()=>{
        setSending(true)
        LaConFetch(`/api/extra/respostas/familia/${familiaId}`,(r)=>{
            if(r.success){
                setPerguntas(r.perguntas)
                setRespostas(r.respostas)
            }
            setSending(false)
        },()=>{
            setSending(false)
        })
    }

    const onChange=(perguntaId,value,mode='')=>{
        if(mode == 'historico'){
            const hold = {...novaResposta}
            hold[perguntaId] = value
            setNovaResposta(hold)
        }else{
            const hold = {...respostas}
            hold[perguntaId] = value
            setRespostas(hold)
        }
    }

    const onSave=()=>{
        setSending(true)
        let respostaTotal = {};
        perguntas.forEach(p => {
            switch(p.modo.interno){
                case 'aberto':
                case 'escolha':
                case 'multipla':
                    respostaTotal[p.id] = respostas[p.id]
                break;
                case 'historico':
                    respostaTotal[p.id] = novaResposta[p.id]
                break;
                default:
            }
        })
        let form ={
            respostas: respostaTotal,
        }
        LaConFetch(`/api/extra/respostas/familia/salvar/${familiaId}`,(r)=>{
            if(r.success){
                swal('Salvo!','Salvo com sucesso!','success')
            }else{
                swal('Erro!',r.message,'error')
            }
            setSending(false)
        },()=>{
            swal('Erro!','Erro ao salvar!','error')
            setSending(false)
        },form)
    }
    return <div>
        <Backdrop style={{zIndex:99}} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <div>
            <Grid container spacing={3}>
                {perguntas.map((item)=>{
                    switch(item.modo.interno){
                        case 'aberto':
                            return <Grid item xs={12} key={item.id}>
                                <InputLabel style={{ marginTop: 20, fontWeight: 'bold', fontSize: 14 }}>
                                    {item.pergunta}
                                </InputLabel>
                                {item.description ? <InputLabel style={{ marginTop: 20, fontSize: 12 }}>
                                    {item.description}
                                </InputLabel>:null}
                                <TextField
                                    variant="outlined" fullWidth
                                    value={respostas[item.id] ?? ''} onChange={(e) => onChange(item.id,e.target.value,'aberto')}
                                    multiline='true' rows={4} label={item.pergunta}
                                    helperText={item.helper}
                                />
                            </Grid>
                        case 'escolha':
                            return <Grid item xs={12} key={item.id}>
                                <InputLabel style={{ marginTop: 20, fontWeight: 'bold', fontSize: 14 }}>
                                    {item.pergunta}
                                </InputLabel>
                                {item.description ? <InputLabel style={{ marginTop: 20, fontSize: 12 }}>
                                    {item.description}
                                </InputLabel>:null}
                                <FormControl variant="outlined" fullWidth margin='normal'>
                                <InputLabel>{item.pergunta}</InputLabel>
                                <Select
                                    value={respostas[item.id] ?? ''} onChange={(e)=>onChange(item.id,e.target.value,'escolha')}
                                    variant="outlined" input={<Input/>} fullWidth
                                >
                                    {item?.options?.map((stuff) => (
                                        <MenuItem key={stuff} value={stuff}>
                                            {stuff}
                                        </MenuItem>
                                    ))}
                                </Select>
                                </FormControl>
                            </Grid>
                        case 'multipla':
                            return <Grid item xs={12} key={item.id}>
                                <InputLabel style={{ marginTop: 20, fontWeight: 'bold', fontSize: 14 }}>
                                    {item.pergunta}
                                </InputLabel>
                                {item.description ? <InputLabel style={{ marginTop: 20, fontSize: 12 }}>
                                    {item.description}
                                </InputLabel>:null}
                                <FormControl variant="outlined" fullWidth margin='normal'>
                                    <InputLabel>{item.pergunta}</InputLabel>
                                    <Select
                                        multiple value={respostas[item.id] ?? []} onChange={(e)=>onChange(item.id,e.target.value,'multipla')}
                                        variant="outlined" input={<Input/>} fullWidth
                                        renderValue={(selected) => <div> {selected.map((value) => <Chip key={value} label={value} />)} </div>}
                                    >
                                    {item?.options?.map((stuff) => (
                                        <MenuItem key={stuff} value={stuff}>
                                            <Checkbox checked={respostas[item.id]?.indexOf(stuff) > -1} />
                                            <ListItemText primary={stuff} />
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        case 'historico':
                            return <Grid item xs={item.size}>
                                <AreaEstudoSocial
                                    historico={respostas[item.id] ?? ''}
                                    novo={novaResposta[item.id] ?? ''} onChange={(value)=>onChange(item.id,value,'historico')}
                                    title={item.pergunta} description={item.descricao}
                                    helper={item.helper}
                                />
                            </Grid>
                        default:
                            return null;
                    }
                })}
            </Grid>
            <div style={{display:"flex",justifyContent:"end",marginTop:20}}>
                <Button variant="contained" color="primary" onClick={onSave}>
                    Salvar
                </Button>
            </div>
        </div>
    </div>
}