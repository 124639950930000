import { Backdrop, CircularProgress } from '@material-ui/core';
import CrudView from 'components/Crud/CrudView';
import { actionIsAllowed } from 'helpers/Permissions';
import { LaConFetch } from 'links/LaConFetch';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';

export default function Creas({...props}){
    const [allowed,setAllowed] = useState({
        add: false, edit:false, delete:false
    })
    const [sending,setSending] = useState(false)

    useEffect(()=>{
        setAllowed({
            edit: actionIsAllowed('loca','update'),
            delete: actionIsAllowed('loca','delete'),
            add:actionIsAllowed('loca','create'),
        })
    },[])

    const onDisable=(id,mode)=>{
        setSending(true)
        let form ={}
        LaConFetch(`/api/creas/disable/${id}`,(r)=>{
            setSending(false)
            if(r.success){
                swal(`${mode ? 'Habilitado':'Desabilitado'} com sucesso!`,"", "success");
            }else{
                swal("Oops",r.message, "error");
            }
        },()=>{},form)
    }

    const onAskDisable=(id,mode=false)=>{
        swal(`Tem certeza que deseja ${mode ? 'habilitar':'desabilitar'} o usuário?`, {
            buttons: {
            remover: mode ? 'Habilitar':'Desabilitar',
            cancel: "Sair"
            },
        }).then((value) => {
            switch (value) {
            case "remover":
                onDisable(id,mode)
                break;
            }
        })
    }

    return <div>
        <Backdrop style={{zIndex:99}} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <CrudView
            title='Local' url='/api/creas'
            canEdit={allowed.edit} canDelete={allowed.delete} canAdd={allowed.add}
            extraAction={rowData =>({
                icon:rowData.disabled ? 'visibility':'visibility_off',
                tooltip:rowData.disabled ? 'Habilitar':'Desabilitar',
                onClick:(e,data)=>{
                    onAskDisable(data.id,rowData.disabled)
                }
            })}
            columns={[
                {
                    field:'id_creas',title:'ID Creas',
                },{
                    field:'nome',title:'Nome',
                },{
                    field:'rua',title:'Rua',
                },{
                    field:'numero',title:'Número',
                },{
                    field:'bairro',title:'Bairro',
                },{
                    field:'cep',title:'Cep',
                },{
                    field:'tipo',title:'Tipo',render:(data)=>{
                        switch(data.tipo){
                            case 'creas':
                                return 'CREAS';
                            case 'cras':
                                return 'CRAS';
                            case 'pop':
                                return 'POP';
                            case 'secretaria':
                                return 'Secretária';
                            default:
                                return data.tipo
                        }
                    }
                },
            ]}
            fields={[
                {
                    field:'nome',label:'Nome',type:'text',grid:{xs:6}
                },{
                    field:'tipo_id',label:'Tipo',type:'select',grid:{xs:6},link:'/api/creas/tipos',nameKey:'nome',dataKey:'id'
                },{
                    field:'ibge',label:'IBGE',type:'text',grid:{xs:6}
                },{
                    field:'id_creas',label:'ID Creas',type:'text',grid:{xs:6}
                },{
                    field:'cep',label:'CEP',type:'text',grid:{xs:12}
                },{
                    field:'rua',label:'Rua',type:'text',grid:{xs:8}
                },{
                    field:'numero',label:'Número',type:'text',grid:{xs:4}
                },{
                    field:'complemento',label:'Complemento',type:'text',grid:{xs:6}
                },{
                    field:'bairro',label:'Bairro',type:'text',grid:{xs:6}
                },{
                    field:'cidade',label:'Cidade',type:'text',grid:{xs:6}
                },{
                    field:'coordenador_id',type:'select',link:'/api/user-geral',dataKey:'id', nameKey:'name',label:'Coordenador',grid:{xs:12}
                },{
                    field:'areas',type:'multi-select',link:'/api/area',dataKey:'id', nameKey:'nome',label:'Area de Abrangência',grid:{xs:12}
                },
            ]}
        />
    </div>
}