import { Backdrop, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LaConFetch } from 'links/LaConFetch';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';
import CurrencyMask from 'views/Outros/CurrencyMask';

export default function EditarEntrada({onClose,edit,novo,...props}){
    const [nome,setNome] = useState('')
    const [descricao,setDescricao] = useState('')
    const [valor,setValor] = useState(0)
    const [start_date,setStart_date] = useState(new Date)
    const [end_date,setEnd_date] = useState(new Date)
    const [parcelado,setParcelado] = useState(false)
    const [validade,setValidade] = useState('')
    const [periodicidade_id,setPeriodicidade_id] = useState(null)
    const [tipo_id,setTipo_id] = useState(null)
    const [tipos,setTipos] = useState([])
    const [periodicidades,setPeriodicidades] = useState([])
    const [loading,setLoading] = useState(false)

    const load=()=>{
        setLoading(true)
        LaConFetch(`/api/financeiro/entrada/show/${edit}`,(r)=>{
            if(r.success){
                setNome(r.data.nome)
                setDescricao(r.data.descricao)
                setValor(parseFloat(r.data.valor).toPrecision(2))
                setStart_date(moment(r.data.start_date,'YYYY-MM-DD').toDate())
                setEnd_date(moment(r.data.end_date,'YYYY-MM-DD').toDate())
                setParcelado(r.data.parcelado)
                setValidade(r.data.validade)
                setPeriodicidade_id(r.data.periodicidade_id)
                setTipo_id(r.data.tipo_id)
            }
            setLoading(false)
        },()=>{
            setLoading(false)
        })
    }

    useEffect(()=>{
        LaConFetch(`/api/financeiro/helper/tipos`,(r)=>{
            if(r.success){
                setTipos(r.entradas)
                setPeriodicidades(r.periodicidade)
            }
        },()=>{

        })
    },[])

    useEffect(()=>{
        if(edit>0){
            load()
        }else if(novo){
            setNome('')
            setDescricao('')
            setValor(0)
            setStart_date(new Date)
            setEnd_date(new Date)
            setParcelado(false)
            setValidade('')
            setPeriodicidade_id(null)
            setTipo_id(null)
        }
    },[edit,novo])

    const onSave=()=>{
        setLoading(true)
        let form ={
            nome, descricao, valor, start_date, end_date, parcelado, validade,
            periodicidade_id, tipo_id
        }
        LaConFetch(`/api/financeiro/entrada/save${edit>0 ? `/${edit}`:''}`,(r)=>{
            if(r.success){
                swal(`Entrada salva com sucesso!`,"", "success");
            }else{
                swal("Oops",r.message, "error");
            }
            setLoading(false)
            onClose()
        },()=>{
            swal("Oops","Houve um erro, tente novamente", "error");
            setLoading(false)
        },form)
    }

    return <div>
        <Backdrop style={{zIndex:99}} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Paper style={{padding:5,margin:5}}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <p style={{paddingBottom:5, fontSize:16,fontWeight:"bold"}}>{novo ? 'Nova entrada':`Editar entrada #${edit}`}</p>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            value={nome} onChange={(e)=>setNome(e.target.value)}
                            margin='normal' label='Nome' fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Tipo</InputLabel>
                            <Select
                                value={tipo_id} onChange={(e)=>setTipo_id(e.target.value)}
                            >
                                {tipos.map(item=><MenuItem value={item.id} key={item.id}>{item.nome}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={descricao} onChange={(e)=>setDescricao(e.target.value)}
                            margin='normal' label='Descrição' fullWidth
                            multiline rows={4}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            inputVariant="outlined" variant="dialog"
                            label={'Data Inicial'} fullWidth margin='normal'
                            value={start_date} onChange={(e)=>setStart_date(e)}
                            format="DD/MM/YYYY"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            inputVariant="outlined" variant="dialog"
                            label={'Data Final'} fullWidth margin='normal'
                            value={end_date} onChange={(e)=>setEnd_date(e)}
                            format="DD/MM/YYYY" disabled={!parcelado}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox checked={!parcelado} onChange={()=>setParcelado(!parcelado)}/>}
                            label="Pagamento Único"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Periodicidade</InputLabel>
                            <Select
                                value={periodicidade_id} onChange={(e)=>setPeriodicidade_id(e.target.value)}
                                disabled={!parcelado}
                            >
                                {periodicidades.map(item=><MenuItem value={item.id} key={item.id}>{item.nome}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                        margin="normal" fullWidth
                        label="Valor" variant="outlined"
                        InputProps={{ inputComponent: CurrencyMask}}
                        value={valor}
                        onChange={(e) => setValor(e.target.value)}
                    />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
            <div style={{display:'flex',justifyContent:'end'}}>
                <Button onClick={()=>onSave()} variant="contained" color="primary">Salvar</Button>
            </div>
        </Paper>
    </div>
}