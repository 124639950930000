import { Button, Modal, Paper } from '@material-ui/core';
import { LaConFetch } from 'links/LaConFetch';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export default function VerifyLife({token,updateToken,...props}){
    const [warn,setWarn] = useState(false)

    const history = useHistory()

    useEffect(()=>{
        let hold = setInterval(()=>{
            if(token){
                LaConFetch(`/api/check-signal`,(r)=>{
                    if(r.success){
                        if(r.warn){
                            setWarn(true)
                        }
                    }else{
                        updateToken('')
                        history.push('/')
                    }
                },()=>{
                })
            }else{
            }
        },50000)
        return ()=>{
            clearInterval(hold)
        }
    },[token])

    const heartBeat=()=>{
        setWarn(false)
        LaConFetch(`/api/heart-beat`,(r)=>{

        },()=>{

        })
    }

    return <div>
        <Modal open={warn} onClose={()=>setWarn(false)}>
            <div className='container' style={{marginTop:50}}>
                <Paper className='text-center' style={{padding:20}}>
                    <h4>A sua sessão irá expirar por inatividade</h4>
                    <Button onClick={heartBeat}>
                        Continuar
                    </Button>
                </Paper>
            </div>
        </Modal>
    </div>
}