import React, { useState, useEffect } from 'react'
import { Paper, TextField, Grid, Button, IconButton, Backdrop, CircularProgress } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import moment from 'moment'
import CloseIcon from '@material-ui/icons/Close';
import { LaConFetch } from '../../links/LaConFetch';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import swal from 'sweetalert';
import EditIcon from '@material-ui/icons/Edit';
import SelectFromAnother from 'components/Crud/SelectFromAnother';

export default function EditAgenda({slot,onClose=()=>{},url="/api/agenda",...props}){
    const [titulo,setTitulo] = useState('')
    const [descricao,setDescricao] = useState('')
    const [start,setStart] = useState(new Date())
    const [end,setEnd] = useState(new Date())
    const [edit,setEdit] = useState(false)
    const [tipo_id,setTipoId] = useState('')
    const [pessoa,setPessoa] = useState('')

    const [sending,setSending] = useState(false)
    useEffect(()=>{
        if(slot){
            setStart(moment(slot.start,'YYYY-MM-DD HH:mm').toDate())
            setEnd(moment(slot.end,'YYYY-MM-DD HH:mm').toDate())
            setTitulo(slot.titulo)
            setDescricao(slot.descricao)
            setTipoId(slot.tipo_id)
            setPessoa(slot?.pessoa?.nm_pessoa)
        }
    },[slot])

    const onSubmit=()=>{
        setSending(true)
        let form = {
            titulo, obs: descricao, start:moment(start).format('YYYY-MM-DD HH:mm'), end:moment(end).format('YYYY-MM-DD HH:mm')
        }
        LaConFetch(`${url}/edit/${slot.rest.id}`,(r)=>{
            if(r.success){
                swal("Atualizado com sucesso!","", "success");
                onClose(true)
            }else{
                swal("Oops","Houve um erro ao editar o agendamento", "error");
            }
            setSending(false)
        },()=>{},form)
    }
    const onDelete=()=>{
        setSending(true)
        let form = {}
        LaConFetch(`${url}/delete/${slot.rest.id}`,(r)=>{
            if(r.success){
                swal("Removido com sucesso!","", "success");
                onClose(true)
            }else{
                swal("Oops","Houve um erro ao remover o agendamento", "error");
            }
            setSending(false)
        },()=>{},form)
    }

    const onAskDelete=()=>{
        swal("Tem certeza que deseja remover o agendamento?", {
            buttons: {
              remover: "Remover",
              cancel: "Sair"
            },
          })
          .then((value) => {
            switch (value) {
              case "remover":
                onDelete()
                break;
            }
          })
    }

    return <div>
        <Backdrop style={{zIndex:99}} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Paper style={{padding:20}}>
            <Grid container spacing={2} justifyContent='space-between'>
                <Grid item xs={11}>
                    <h5>Editar agendamento:</h5>
                </Grid>
                <Grid item xs={1}>
                    <div style={{width:'100%',textAlign:'right'}}>
                        <IconButton onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            disabled value={pessoa} label='Pessoa'
                            fullWidth margin="normal" variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDateTimePicker
                            inputVariant="outlined" variant="dialog"
                            ampm={false}
                            label="Começo"
                            value={start} disabled={!edit}
                            onChange={setStart}
                            fullWidth margin='normal'
                            format="DD/MM/yyyy HH:mm"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDateTimePicker
                            inputVariant="outlined" variant="dialog"
                            ampm={false}
                            label="Término"
                            value={end} disabled={!edit}
                            onChange={setEnd}
                            fullWidth margin='normal'
                            format="DD/MM/yyyy HH:mm"
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
            <TextField
                fullWidth margin='normal' variant='outlined'
                label='Título' required
                value={titulo} onChange={(e)=>setTitulo(e.target.value)} disabled={!edit}
            />
            <TextField
                fullWidth margin='normal' variant='outlined'
                label='Observação' multiline rows={4}
                value={descricao} onChange={(e)=>setDescricao(e.target.value)} disabled={!edit}
            />
            <SelectFromAnother
                link={'/api/agenda/tipo'} value={tipo_id} onChange={setTipoId} label="Tipo de agendamento" readOnly={!edit}
            />
            <Grid container justifyContent='space-between'>
                <Grid item xs={1}>
                    <Button color='secondary' variant='contained' onClick={onAskDelete}>
                        <DeleteIcon/> Deletar
                    </Button>
                </Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={1}>
                    {edit ? <Button color='primary' variant='contained' onClick={onSubmit}>
                        <SaveIcon/> Salvar
                    </Button>:<Button color='secondary' variant='contained' onClick={()=>setEdit(true)}>
                        <EditIcon/> Editar
                    </Button>}
                </Grid>
            </Grid>
        </Paper>
    </div>
}