import { LaConFetch } from 'links/LaConFetch';
import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';

const COLORS = ['#0088FE', '#FF0042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};


export default function SexoGraph({...props}){
    const [data,setData] = useState([])

    useEffect(()=>{
        LaConFetch('/api/graph/sexo',(r)=>{
            if(r.success){
                setData(r.data)
            }
        },()=>{

        })
    },[])

    return <div style={{width:'100%',height:'100%'}}>
        <b style={{fontSize:16}}>Beneficiados por Sexo</b>
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
                <Tooltip/>
                <Legend
                    align='right' verticalAlign='middle'
                    layout='vertical'
                />
                <Pie
                    data={data} outerRadius={80}
                    cx="50%" cy="50%"
                    labelLine={false} label={renderCustomizedLabel}
                    fill="#8884d8" dataKey="value" nameKey="label"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    </div>
}