import CrudView from 'components/Crud/CrudView';
import React from 'react';

export default function Area({...props}){
    return <div>
        <CrudView
            url={`/api/area`}
            columns={[
                {
                    title:'Nome',field:'nome'
                }
            ]}
            title='Áreas'
            fields={[
                {
                    field:'nome',label:'Nome',type:'text',grid:{xs:12}
                },{
                    field:'local',label:'Localização',type:'map-point',grid:{xs:12}
                },{
                    field:'obs',label:'Observações',type:'text',grid:{xs:12}, input: {multiline:true, rows:4}
                },
            ]}
            dynamic remoteLoad 
            canEdit canDelete topBar canAdd canSee 
        />
    </div>
}