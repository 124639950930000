import CrudView from 'components/Crud/CrudView';
import moment from 'moment';
import React from 'react';

export default function Log({...props}){
    return <div>
        <CrudView
            url={`/api/log/index`} title="Log de Acesso"
            columns={[
                {field:'action',title:'Ação'},
                {field:'ip',title:'IP'},
                {field:'funcionario.name',title:'Funcionário'},
                {field:'created_at',title:'Acessado em',render:(data)=>moment(data.created_at,'YYYY-MM-DDTHH:mm:ss').format('HH:mm:ss DD/MM/YYYY')},
            ]}
            canEdit={false} canAdd={false} canDelete={false} canSee={true} dynamic={true}
            fields={[
                {
                    field:'action',label:'Ação',type:'text',grid:{xs:12}
                },{
                    field:'funcionario.name',label:'Funcionário',type:'text',grid:{xs:6},
                },{
                    field:'ip',label:'Ip',type:'text',grid:{xs:6},
                },{
                    field:'created_at',label:'Acessado em',type:'datetime',grid:{xs:12}
                },
            ]}
        />
    </div>
}