import CrudView from 'components/Crud/CrudView';
import React from 'react';

export default function Servico({...props}){
    return <div>
        <CrudView
            url={`/api/servico`}
            columns={[
                {
                    title:'Nome',field:'nome'
                },{
                    title:'Descrição',field:'descricao'
                }
            ]}
            title='Serviços'
            fields={[
                {
                    field:'nome',label:'Nome',type:'text',grid:{xs:6}
                },{
                    field:'tipificado',label:'Tipo',type:'select',grid:{xs:6},options:[
                        {id:0,nome:'Não tipificado'},
                        {id:1,nome:'Tipificado'}
                    ]
                },{
                    field:'descricao',label:'Descrição',type:'text',grid:{xs:12}, input: {multiline:true, rows:4}
                },{
                    field:'public_alvo',label:'Público Alvo',type:'text',grid:{xs:6}, input: {multiline:true, rows:4}
                },{
                    field:'instrumentos',label:'Instrumentos Técnico Operaticos',type:'text',grid:{xs:6}, input: {multiline:true, rows:4}
                },{
                    field:'tipos',label:'Tipos de Unidades',type:'multi-select',link: '/api/creas/tipos',nameKey:'nome',dataKey:'id',grid:{xs:12}
                },{
                    field:'obs',label:'Observações',type:'text',grid:{xs:12}, input: {multiline:true, rows:4}
                },
            ]}
            dynamic remoteLoad 
            canEdit canDelete topBar canAdd canSee
        />
    </div>
}