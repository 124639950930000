import { Paper, Button, TextField, Backdrop, CircularProgress } from '@material-ui/core';
import { link } from 'links/Links';
import React, { useState } from 'react';
import swal from 'sweetalert';
import CPFMask from 'views/Outros/CPFMask';

export default function ForgotPassword({onClose,...props}){
    const [email,setEmail] = useState('')
    const [cpf,setCpf] = useState('')
    const [success,setSuccess] = useState(false)
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState({has:false,message:''})

    const onRecoverPass=()=>{
        setLoading(true)
        let form = {
            email:email,
            cpf:cpf,
        }
        fetch(`${link}/api/recoverPassword`,{
            method:'POST',
            body:JSON.stringify(form),
            headers:{
                "Content-Type":'application/json',
                Accept:'application/json'
            }
        })
        .then(res => res.json())
        .then((r)=>{
            if(r.success){
                swal("Email enviado com sucesso","","success")
            }else{
                swal("Erro",r.message,"error")
            }
            setLoading(false)
        }).catch(()=>{
            swal("Erro","Erro ao enviar email","error")
            setLoading(false)
        })
    }

    return <div className='container' style={{marginTop:20}}>
        <Backdrop style={{zIndex:99}} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Paper style={{padding:10}}>
            <div style={{display:'flex'}}>
                Esqueci a minha senha:
            </div>
            <TextField
                margin="normal" fullWidth
                label="Email" value={email} variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                margin="normal" fullWidth
                label="CPF" value={cpf} variant="outlined"
                onChange={(e) => setCpf(e.target.value)}
                InputProps={{ inputComponent: CPFMask}}
            />
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <Button onClick={()=>onClose()} variant="contained" color="secondary">
                    Cancelar
                </Button>
                <Button onClick={onRecoverPass} variant="contained" color="primary">
                    Enviar email
                </Button>
            </div>
        </Paper>
    </div>
}