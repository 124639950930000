import CrudView from 'components/Crud/CrudView'
import { actionIsAllowed } from 'helpers/Permissions'
import React, { useEffect, useState } from 'react'

export default function ProdutoAlmoxarifado({...props}){
    const [allowed,setAllowed] = useState({
        add: false, edit:false, delete:false
    })

    useEffect(()=>{
        setAllowed({
            edit: actionIsAllowed('prod','update'),
            delete: actionIsAllowed('prod','delete'),
            add:actionIsAllowed('prod','create'),
        })
    },[])

    return <div>
        <CrudView
            url={'/api/almoxarifado/produto'}
            columns={[
                {field:'nome',title:'Nome'},
                {field:'unidade',title:'Unidade'},
                {field:'estoque_minimo',title:'Estoque Mínimo'},
            ]}
            title='Produto'
            canEdit={allowed.edit} canDelete={allowed.delete} canAdd={allowed.add}
            fields={[
                {
                    field:'nome',label:'Nome',type:'text',grid:{xs:6}
                },{
                    field:'categoria_id',label:'Categoria',type:'select',grid:{xs:6},link:'/api/almoxarifado/categoria',nameKey:'nome',dataKey:'id'
                },{
                    field:'descricao',label:'Descrição',type:'text',input:{rows:4,multiline:true},grid:{xs:12}
                },{
                    field:'unidade',label:'Unidade',type:'text',grid:{xs:6}
                },{
                    field:'estoque_minimo',label:'Estoque Mínimo',type:'text',grid:{xs:6},input:{type:'number'}
                },{
                    field:'obs',label:'Observação',type:'text',input:{rows:4,multiline:true},grid:{xs:12}
                },
            ]}
        />
    </div>
}