import { Checkbox, Chip, FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { LaConFetch } from 'links/LaConFetch';
import React, { useEffect, useState } from 'react';

export default function MultiSelectFromAnother({link,value,onChange,nameKey='nome',dataKey='id',readOnly=false,label,required=false,...props}){
    const [options,setOptions] = useState([])

    useEffect(()=>{
        if(link){
            LaConFetch(`${link}`,(r)=>{
                if(r.success){
                    setOptions(r.items)
                }
            },()=>{})
        }
    },[link])

    return <FormControl variant="outlined" fullWidth margin='normal'>
        <InputLabel>{label}</InputLabel>
        <Select
            multiple value={value} onChange={(e)=>onChange(e.target.value)}
            required={required} disabled={readOnly} variant="outlined"
            input={<Input/>}
            renderValue={(selected) => (
                <div> {selected.map((value) => {
                    const item = options.find(i=>i[dataKey]==value)
                    if(item){
                        return <Chip key={value} label={item[nameKey]} />
                    }
                })} </div>
            )}
            {...props}
        >
        {options.map((name) => (
            <MenuItem key={name[dataKey]} value={name[dataKey]}>
                <Checkbox checked={value.indexOf(name[dataKey]) > -1} />
                <ListItemText primary={name[nameKey]} />
            </MenuItem>
        ))}
        </Select>
    </FormControl>
}